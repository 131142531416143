import React, { useState } from 'react'
import { Button } from './components/ui/button'
import { Plus } from 'lucide-react'

const StyleInput = ({ onStyleSubmit, children, isDarkMode }) => {
    const [fileName, setFileName] = useState('')
    const [fileType, setFileType] = useState('sty')
    const [styleContent, setStyleContent] = useState('')
    const [showModal, setShowModal] = useState(false)

    const handleSubmit = () => {
        const fullFileName = `${fileName}.${fileType}`
        onStyleSubmit(fullFileName, styleContent)
        setFileName('')
        setStyleContent('')
        setShowModal(false)
    }

    const triggerElement = children ? (
        React.cloneElement(children, { onClick: () => setShowModal(true) })
    ) : (
        <Button variant="outline" size="sm" onClick={() => setShowModal(true)}>
            <Plus className="h-4 w-4" />
        </Button>
    )

    return (
        <>
            {triggerElement}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-[100]">
                    <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white'} p-4 rounded-lg w-[800px]`}>
                        <h2 className="text-lg font-bold mb-4">
                            Ajouter un fichier annexe, de style, de classe ou de bibliographie
                        </h2>
                        <div className="flex mb-2">
                            <input
                                className={`flex-grow p-2 border rounded-l ${
                                    isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300'
                                }`}
                                placeholder="Nom du fichier"
                                value={fileName}
                                onChange={(e) => setFileName(e.target.value)}
                            />
                            <select
                                className={`p-2 border rounded-r min-w-[180px] ${
                                    isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300'
                                }`}
                                value={fileType}
                                onChange={(e) => setFileType(e.target.value)}
                            >
                                <option value="sty">Style (.sty)</option>
                                <option value="cls">Classe (.cls)</option>
                                <option value="bib">Bibliographie (.bib)</option>
                                <option value="tex">Fichier annexe (.tex)</option>
                            </select>
                        </div>
                        <textarea
                            className={`w-full h-40 p-2 mb-2 border rounded ${
                                isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300'
                            }`}
                            placeholder="Contenu du fichier annexe, de style, de classe ou de bibliographie"
                            value={styleContent}
                            onChange={(e) => setStyleContent(e.target.value)}
                        />
                        <div className="flex justify-end">
                            <Button onClick={() => setShowModal(false)} variant="outline" className="mr-2">
                                Annuler
                            </Button>
                            <Button onClick={handleSubmit}>Ajouter</Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default StyleInput
