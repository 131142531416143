import React, { useState, useRef, useEffect } from 'react'
import { ChevronDown, ChevronRight } from 'lucide-react'

const LevelSelector = ({ onExerciseSelect, currentExercise, latexExercises, isDarkMode }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedLevel, setSelectedLevel] = useState('1')
    const menuRef = useRef(null)

    const filteredExercises = latexExercises.filter((ex) => ex.level.toString() === selectedLevel)

    const levels = [
        { value: '1', label: 'Débutant' },
        { value: '2', label: 'Intermédiaire' },
        { value: '3', label: 'Avancé' },
    ]

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const levelText = levels.find((l) => l.value === selectedLevel)?.label || 'Niveau'

    return (
        <div className="relative" ref={menuRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={`flex items-center gap-2 px-3 py-2 rounded-lg
          ${isDarkMode ? 'hover:bg-gray-800 text-gray-200' : 'hover:bg-gray-100 text-gray-700'}`}
            >
                <span>Niveau : {levelText}</span>
                <ChevronDown className="h-4 w-4" />
            </button>

            {isOpen && (
                <div
                    className={`absolute right-0 top-full mt-2 w-64 rounded-lg shadow-lg overflow-hidden z-50
          ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
                >
                    {/* Onglets de niveau */}
                    <div
                        className="flex border-b border-opacity-10
            ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}"
                    >
                        {levels.map((level) => (
                            <button
                                key={level.value}
                                onClick={() => setSelectedLevel(level.value)}
                                className={`flex-1 py-2 px-3 text-sm transition-colors
                  ${
                      selectedLevel === level.value
                          ? isDarkMode
                              ? 'bg-gray-700 text-white'
                              : 'bg-gray-100 text-gray-900'
                          : isDarkMode
                          ? 'text-gray-300 hover:bg-gray-700'
                          : 'text-gray-700 hover:bg-gray-50'
                  }`}
                            >
                                {level.label}
                            </button>
                        ))}
                    </div>

                    {/* Liste des exercices */}
                    <div
                        className="max-h-[300px] overflow-y-auto"
                        style={{
                            scrollbarWidth: 'thin',
                            scrollbarColor: isDarkMode ? '#4B5563 transparent' : '#9CA3AF transparent',
                        }}
                    >
                        {filteredExercises.map((exercise) => (
                            <button
                                key={exercise.id}
                                onClick={() => {
                                    onExerciseSelect(exercise.id - 1)
                                    setIsOpen(false)
                                }}
                                className={`w-full flex items-center justify-between p-3 text-sm transition-colors
                  ${
                      currentExercise === exercise.id - 1
                          ? isDarkMode
                              ? 'bg-gray-700 text-white'
                              : 'bg-gray-100 text-gray-900'
                          : isDarkMode
                          ? 'text-gray-300 hover:bg-gray-700'
                          : 'text-gray-700 hover:bg-gray-50'
                  }`}
                            >
                                <span className="truncate text-left">
                                    {exercise.id}. {exercise.title}
                                </span>
                                <ChevronRight className="h-4 w-4 ml-2 shrink-0 opacity-50" />
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default LevelSelector
