import React, { useState } from 'react'
import { Button } from './components/ui/button'
import { FileCode, File, Columns, Maximize2, Minimize2, Plus } from 'lucide-react'
import StyleInput from './StyleInput'

const CustomTooltip = ({ children, tooltip, isDarkMode }) => {
    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <div className="relative" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            {children}
            {showTooltip && (
                <div
                    className={`absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 text-xs rounded whitespace-nowrap z-20 ${
                        isDarkMode
                            ? 'bg-gray-800 text-white'
                            : 'bg-white text-gray-800 border border-gray-200 shadow-sm'
                    }`}
                >
                    {tooltip}
                </div>
            )}
        </div>
    )
}

const DisplayToggle = ({
    displayMode,
    setDisplayMode,
    isDarkMode,
    onToggleExpandedView,
    isExpandedView,
    onStyleSubmit,
}) => {
    return (
        <div className={`flex justify-center space-x-2 py-1 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
            <CustomTooltip tooltip="Ajouter un fichier de style ou une classe" isDarkMode={isDarkMode}>
                <StyleInput onStyleSubmit={onStyleSubmit} isDarkMode={isDarkMode}>
                    <Button variant="outline" size="sm">
                        <Plus className="h-4 w-4" />
                    </Button>
                </StyleInput>
            </CustomTooltip>

            <CustomTooltip tooltip="N'afficher que l'éditeur" isDarkMode={isDarkMode}>
                <Button
                    variant={displayMode === 'editor' ? 'default' : 'outline'}
                    size="sm"
                    onClick={() => setDisplayMode('editor')}
                >
                    <FileCode className="h-4 w-4" />
                </Button>
            </CustomTooltip>

            <CustomTooltip tooltip="Afficher l'éditeur et le lecteur" isDarkMode={isDarkMode}>
                <Button
                    variant={displayMode === 'both' ? 'default' : 'outline'}
                    size="sm"
                    onClick={() => setDisplayMode('both')}
                >
                    <Columns className="h-4 w-4" />
                </Button>
            </CustomTooltip>

            <CustomTooltip tooltip="N'afficher que le lecteur" isDarkMode={isDarkMode}>
                <Button
                    variant={displayMode === 'reader' ? 'default' : 'outline'}
                    size="sm"
                    onClick={() => setDisplayMode('reader')}
                >
                    <File className="h-4 w-4" />
                </Button>
            </CustomTooltip>

            <Button variant="outline" size="sm" onClick={onToggleExpandedView}>
                {isExpandedView ? <Minimize2 className="h-4 w-4" /> : <Maximize2 className="h-4 w-4" />}
            </Button>
        </div>
    )
}

export default DisplayToggle
