import React, { useState, useRef, useEffect } from 'react'
import { Button } from './components/ui/button'
import { Editor, useMonaco } from '@monaco-editor/react'
import { Sun, Moon, ChevronLeft, ChevronRight, ChevronDown, Code, Play } from 'lucide-react'
import latexExercises from './latexExercises'
import PDFViewer from './PDFViewer'
import ResizablePanels from './ResizablePanels'
import DisplayToggle from './DisplayToggle'
import LevelSelector from './LevelSelector'

const App = () => {
    const [currentExercise, setCurrentExercise] = useState(0)
    const [pdfUrl, setPdfUrl] = useState('')
    const [output, setOutput] = useState('')
    const [error, setError] = useState('')
    const [isDarkMode, setIsDarkMode] = useState(false)
    const [showExerciseMenu, setShowExerciseMenu] = useState(false)
    const [displayMode, setDisplayMode] = useState('both')
    const [showEditor, setShowEditor] = useState(true)
    const [customFiles, setCustomFiles] = useState({})
    const [isEnonce, setIsEnonce] = useState(true)
    const [isExpandedView, setIsExpandedView] = useState(false)
    const [currentContent, setCurrentContent] = useState(latexExercises[0].initialCode)
    const [modifiedContent, setModifiedContent] = useState({})

    const menuRef = useRef(null)

    const handleBeamerClick = (e) => {
        e.preventDefault()
        window.open('/api/beamer-presentation', '_blank', 'noopener,noreferrer')
    }

    // À ajouter dans le composant App
    const handleExpandedView = () => {
        // Toggle expanded view
        setIsExpandedView(!isExpandedView)

        // Toggle fullscreen
        if (!isExpandedView) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            }
        }
    }

    const toggleEnonceSolution = () => {
        // Sauvegarder l'état actuel
        setModifiedContent((prev) => ({
            ...prev,
            [currentExercise]: {
                ...(prev[currentExercise] || {}),
                [isEnonce ? 'enonce' : 'solution']: currentContent,
            },
        }))

        const newIsEnonce = !isEnonce
        setIsEnonce(newIsEnonce)

        // Charger le contenu approprié (modifié ou original)
        const newContent =
            modifiedContent[currentExercise]?.[newIsEnonce ? 'enonce' : 'solution'] ||
            (newIsEnonce ? latexExercises[currentExercise].initialCode : latexExercises[currentExercise].solution)
        setCurrentContent(newContent)
    }

    const handleEditorChange = (newContent) => {
        setCurrentContent(newContent)
    }

    const handleStyleSubmit = (fileName, content) => {
        setCustomFiles((prev) => ({
            ...prev,
            [fileName]: content,
        }))
    }

    useEffect(() => {
        document.body.style.backgroundColor = isDarkMode ? '#111827' : '#f3f4f6'
        document.body.classList.add('bg-transition')
    }, [isDarkMode])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowExerciseMenu(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const monaco = useMonaco()

    useEffect(() => {
        if (monaco) {
            monaco.languages.register({ id: 'latex' })
            monaco.languages.setMonarchTokensProvider('latex', {
                tokenizer: {
                    root: [
                        [/\\[a-zA-Z]+/, 'keyword'],
                        [/\{|\}|\[|\]/, 'bracket'],
                        [/%.*$/, 'comment'],
                        [/\$.*?\$/, 'string'],
                    ],
                },
            })

            monaco.editor.defineTheme('latex-theme-light', {
                base: 'vs',
                inherit: true,
                rules: [
                    { token: 'keyword', foreground: '569CD6', fontStyle: 'bold' },
                    { token: 'bracket', foreground: 'FF5700' },
                    { token: 'comment', foreground: '608B4E' },
                    { token: 'string', foreground: 'CE9178' },
                ],
                colors: {
                    'editor.foreground': '#000000',
                    'editor.background': '#FFFFFF',
                    'editor.lineHighlightBackground': '#F0F0F0',
                },
            })

            monaco.editor.defineTheme('latex-theme-dark', {
                base: 'vs-dark',
                inherit: true,
                rules: [
                    { token: 'keyword', foreground: '569CD6', fontStyle: 'bold' },
                    { token: 'bracket', foreground: 'FF5700' },
                    { token: 'comment', foreground: '608B4E' },
                    { token: 'string', foreground: 'CE9178' },
                ],
                colors: {
                    'editor.foreground': '#D4D4D4',
                    'editor.background': '#1f2937',
                    'editor.lineHighlightBackground': '#2A2A2A',
                },
            })
        }
    }, [monaco])

    useEffect(() => {
        if (monaco) {
            const baseTheme = isDarkMode ? 'vs-dark' : 'vs'

            monaco.editor.defineTheme('custom-theme', {
                base: baseTheme,
                inherit: true,
                rules: [{ token: 'delimiter.bracket', foreground: isDarkMode ? 'ff5700' : 'ff0000' }],
                colors: {
                    'editor.background': isDarkMode ? '#1f2937' : '#ffffff',
                },
            })

            monaco.editor.setTheme('custom-theme')
        }
    }, [monaco, isDarkMode, showEditor])

    const compileLaTeX = async () => {
        try {
            // const response = await fetch('http://localhost:5000/compile', {
            const response = await fetch('/api/compile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ currentContent, customFiles }),
            })

            const contentType = response.headers.get('content-type')
            if (contentType && contentType.indexOf('application/pdf') !== -1) {
                const blob = await response.blob()
                const pdfUrl = URL.createObjectURL(blob)
                setPdfUrl(pdfUrl)
                setError('')
            } else {
                setPdfUrl('')
                const data = await response.json()
                if (!response.ok) {
                    setError(data.error || 'Une erreur est survenue lors de la compilation')
                    setOutput(data.details || '')
                }
            }
        } catch (error) {
            setPdfUrl('')
            console.error('Erreur lors de la compilation:', error)
            setError('Erreur de connexion au serveur ou de traitement de la réponse')
        }
    }

    const getExerciseContent = (exerciseIndex, isEnonceView) => {
        const exerciseModifications = modifiedContent[exerciseIndex]
        if (exerciseModifications) {
            return isEnonceView ? exerciseModifications.enonce : exerciseModifications.solution
        }
        return isEnonceView ? latexExercises[exerciseIndex].initialCode : latexExercises[exerciseIndex].solution
    }

    const nextExercise = () => {
        if (currentExercise < latexExercises.length - 1) {
            const nextIndex = currentExercise + 1

            // Sauvegarder les modifications de l'exercice actuel
            setModifiedContent((prev) => ({
                ...prev,
                [currentExercise]: {
                    ...(prev[currentExercise] || {}),
                    [isEnonce ? 'enonce' : 'solution']: currentContent,
                },
            }))

            // Charger l'exercice suivant
            setCurrentExercise(nextIndex)
            setIsEnonce(true)
            const nextContent = modifiedContent[nextIndex]?.enonce || latexExercises[nextIndex].initialCode
            setCurrentContent(nextContent)
            setPdfUrl('')
            setOutput('')
            setError('')
        }
    }

    const previousExercise = () => {
        if (currentExercise > 0) {
            const prevIndex = currentExercise - 1

            // Sauvegarder les modifications de l'exercice actuel
            setModifiedContent((prev) => ({
                ...prev,
                [currentExercise]: {
                    ...(prev[currentExercise] || {}),
                    [isEnonce ? 'enonce' : 'solution']: currentContent,
                },
            }))

            // Charger l'exercice précédent
            setCurrentExercise(prevIndex)
            setIsEnonce(true)
            const prevContent = modifiedContent[prevIndex]?.enonce || latexExercises[prevIndex].initialCode
            setCurrentContent(prevContent)
            setPdfUrl('')
            setOutput('')
            setError('')
        }
    }

    const selectExercise = (index) => {
        // Sauvegarder les modifications de l'exercice actuel
        setModifiedContent((prev) => ({
            ...prev,
            [currentExercise]: {
                ...(prev[currentExercise] || {}),
                [isEnonce ? 'enonce' : 'solution']: currentContent,
            },
        }))

        // Charger le nouvel exercice
        setCurrentExercise(index)
        setIsEnonce(true)
        const newContent = modifiedContent[index]?.enonce || latexExercises[index].initialCode
        setCurrentContent(newContent)
        setPdfUrl('')
        setOutput('')
        setError('')
        setShowExerciseMenu(false)
    }

    const showSolution = () => {
        setCurrentContent(latexExercises[currentExercise].solution)
    }

    const renderDescription = (description) => {
        return <p className="text-base" dangerouslySetInnerHTML={{ __html: description }} />
    }

    const resetCurrentExercise = () => {
        const newContent = isEnonce
            ? latexExercises[currentExercise].initialCode
            : latexExercises[currentExercise].solution

        setCurrentContent(newContent)
        setModifiedContent((prev) => {
            const newState = { ...prev }
            if (isEnonce) {
                if (newState[currentExercise]) {
                    delete newState[currentExercise].enonce
                    if (!newState[currentExercise].solution) delete newState[currentExercise]
                }
            }
            return newState
        })
    }

    const handleExerciseSelect = (index) => {
        setCurrentExercise(index)
        setIsEnonce(true)
        const newContent = modifiedContent[index]?.enonce || latexExercises[index].initialCode
        setCurrentContent(newContent)
        setPdfUrl('')
        setOutput('')
        setError('')
    }

    const resetAllExercises = () => {
        setModifiedContent({})
        setCurrentContent(
            isEnonce ? latexExercises[currentExercise].initialCode : latexExercises[currentExercise].solution
        )
    }

    return (
        <div className={`min-h-screen w-full ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
            <div
                className={`max-w-[1920px] mx-auto ${
                    isExpandedView ? 'flex flex-col h-screen px-2 gap-1' : 'px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8'
                }`}
            >
                {/* Mode normal */}
                {!isExpandedView && (
                    <header className="flex flex-col">
                        {/* Première ligne: Logo, Titre, Actions */}
                        <div className="flex items-center justify-between mb-4 pt-0">
                            {/* Logo */}
                            <div className="w-10">
                                <a
                                    href="https://apps.edulatex.xyz"
                                    className={`inline-flex p-2 rounded-full transition-colors duration-200 ${
                                        isDarkMode
                                            ? 'text-gray-100 hover:bg-gray-800'
                                            : 'text-gray-700 hover:bg-gray-100'
                                    }`}
                                    title="Retour à EduLaTeX"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-5 h-5"
                                    >
                                        <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                                        <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                                    </svg>
                                </a>
                            </div>

                            {/* Titre */}
                            <h1 className="text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">
                                Apprendre LaTeX
                            </h1>

                            {/* Actions */}
                            <div className="flex items-center space-x-2">
                                <button
                                    onClick={resetAllExercises}
                                    title="Réinitialiser tous les exercices"
                                    className={`p-2 rounded-full transition-colors duration-200 ${
                                        isDarkMode
                                            ? 'bg-gray-800 text-gray-100 hover:bg-gray-700'
                                            : 'bg-white text-gray-700 hover:bg-gray-200'
                                    }`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                        strokeWidth="2"
                                    >
                                        <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
                                        <path d="M3 3v5h5" />
                                        <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
                                        <path d="M16 21h5v-5" />
                                    </svg>
                                </button>
                                <button
                                    onClick={() => setIsDarkMode(!isDarkMode)}
                                    className={`p-2 rounded-full transition-colors duration-200 ${
                                        isDarkMode
                                            ? 'bg-gray-800 text-gray-100 hover:bg-gray-700'
                                            : 'bg-white text-gray-700 hover:bg-gray-200'
                                    }`}
                                >
                                    {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
                                </button>
                            </div>
                        </div>

                        {/* Message de bienvenue */}
                        <div className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-4`}>
                            <p className="text-lg">
                                Bienvenue dans cet outil interactif qui vous propose une série d'exercices pour
                                maîtriser le langage LaTeX, de la création de documents simples aux fonctionnalités
                                avancées. Chaque exercice dispose d'un énoncé et de sa solution.{' '}
                                <button
                                    onClick={handleBeamerClick}
                                    className={`text-blue-500 hover:text-blue-600 ${
                                        isDarkMode ? 'text-blue-400 hover:text-blue-300' : ''
                                    } focus:outline-none`}
                                >
                                    Consultez notre présentation complète
                                </button>{' '}
                                pour une introduction détaillée au langage LaTeX.
                            </p>
                        </div>

                        {/* Deuxième ligne: Navigation et contrôles */}
                        <div className="flex items-center justify-between">
                            {/* Gauche: Titre exercice et navigation */}
                            <div className="flex items-center space-x-4 min-w-0">
                                {/* Remplacer les deux éléments (h2 et div du menu) par */}
                                <div className="relative">
                                    <button
                                        onClick={() => setShowExerciseMenu(!showExerciseMenu)}
                                        className="flex items-center space-x-2 text-lg font-semibold"
                                    >
                                        <span>
                                            {currentExercise + 1}. {latexExercises[currentExercise].title}
                                        </span>
                                        <ChevronDown className="h-4 w-4" />
                                    </button>
                                    {showExerciseMenu && (
                                        <div
                                            ref={menuRef}
                                            className={`absolute left-0 mt-2 w-64 ${
                                                isDarkMode ? 'bg-gray-800' : 'bg-white'
                                            } rounded-md shadow-lg overflow-hidden z-10 max-h-[400px] overflow-y-auto`}
                                        >
                                            {latexExercises.map((exercise, index) => (
                                                <button
                                                    key={exercise.id}
                                                    onClick={() => selectExercise(index)}
                                                    className={`block w-full text-left px-4 py-2 text-sm ${
                                                        index === currentExercise
                                                            ? isDarkMode
                                                                ? 'bg-gray-700 text-white'
                                                                : 'bg-gray-200 text-gray-900'
                                                            : isDarkMode
                                                            ? 'text-gray-200 hover:bg-gray-700'
                                                            : 'text-gray-800 hover:bg-gray-100'
                                                    }`}
                                                >
                                                    {index + 1}. {exercise.title}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Centre: DisplayToggle parfaitement centré */}
                            <div className="absolute left-1/2 transform -translate-x-1/2 z-10 bg-inherit">
                                <DisplayToggle
                                    displayMode={displayMode}
                                    setDisplayMode={setDisplayMode}
                                    isDarkMode={isDarkMode}
                                    onToggleExpandedView={handleExpandedView}
                                    isExpandedView={isExpandedView}
                                    onStyleSubmit={handleStyleSubmit}
                                />
                            </div>

                            {/* Droite: Sélecteur de niveau */}
                            <LevelSelector
                                onExerciseSelect={handleExerciseSelect}
                                currentExercise={currentExercise}
                                latexExercises={latexExercises}
                                isDarkMode={isDarkMode}
                            />
                        </div>

                        {/* Description de l'exercice */}
                        <div className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mt-0 mb-2`}>
                            {renderDescription(latexExercises[currentExercise].description)}
                        </div>
                    </header>
                )}

                {/* Mode étendu */}
                {isExpandedView && (
                    <>
                        <div
                            className={`fixed top-0 left-0 right-0 z-10 ${
                                isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
                            } p-2`}
                        >
                            <div className="flex items-center justify-between max-w-[1920px] mx-auto px-2">
                                {/* Gauche: Titre exercice et navigation */}
                                <div className="flex items-center space-x-4 min-w-0">
                                    {/* Remplacer les deux éléments (h2 et div du menu) par */}
                                    <div className="relative">
                                        <button
                                            onClick={() => setShowExerciseMenu(!showExerciseMenu)}
                                            className="flex items-center space-x-2 text-lg font-semibold"
                                        >
                                            <span>
                                                {currentExercise + 1}. {latexExercises[currentExercise].title}
                                            </span>
                                            <ChevronDown className="h-4 w-4" />
                                        </button>
                                        {showExerciseMenu && (
                                            <div
                                                ref={menuRef}
                                                className={`absolute left-0 mt-2 w-64 ${
                                                    isDarkMode ? 'bg-gray-800' : 'bg-white'
                                                } rounded-md shadow-lg overflow-hidden z-10 max-h-[400px] overflow-y-auto`}
                                            >
                                                {latexExercises.map((exercise, index) => (
                                                    <button
                                                        key={exercise.id}
                                                        onClick={() => selectExercise(index)}
                                                        className={`block w-full text-left px-4 py-2 text-sm ${
                                                            index === currentExercise
                                                                ? isDarkMode
                                                                    ? 'bg-gray-700 text-white'
                                                                    : 'bg-gray-200 text-gray-900'
                                                                : isDarkMode
                                                                ? 'text-gray-200 hover:bg-gray-700'
                                                                : 'text-gray-800 hover:bg-gray-100'
                                                        }`}
                                                    >
                                                        {index + 1}. {exercise.title}
                                                    </button>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Centre: DisplayToggle parfaitement centré */}
                                <div className="absolute left-1/2 transform -translate-x-1/2 z-10">
                                    <DisplayToggle
                                        displayMode={displayMode}
                                        setDisplayMode={setDisplayMode}
                                        isDarkMode={isDarkMode}
                                        onToggleExpandedView={handleExpandedView}
                                        isExpandedView={isExpandedView}
                                        onStyleSubmit={handleStyleSubmit}
                                    />
                                </div>

                                {/* Droite: Sélecteur de niveau */}
                                <LevelSelector
                                    onExerciseSelect={handleExerciseSelect}
                                    currentExercise={currentExercise}
                                    latexExercises={latexExercises}
                                    isDarkMode={isDarkMode}
                                />
                            </div>
                        </div>
                        <div className="h-[40px]" /> {/* Espace pour compenser la barre fixe */}
                        <div className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} px-2 mt-2`}>
                            {renderDescription(latexExercises[currentExercise].description)}
                        </div>
                    </>
                )}

                {/* Zone principale incluant la description et l'éditeur */}
                <div className={`flex-1 ${isExpandedView ? 'pt-2' : 'mt-4'}`}>
                    {/* Éditeur et prévisualisation */}
                    <ResizablePanels
                        leftPanel={
                            <div className="w-full h-full">
                                <Editor
                                    height="100%"
                                    language="latex"
                                    value={currentContent}
                                    onChange={handleEditorChange}
                                    theme="custom-theme"
                                    options={{
                                        minimap: { enabled: false },
                                        fontSize: 14,
                                        padding: {
                                            top: 16,
                                            bottom: 16,
                                        },
                                        wordWrap: 'on',
                                        scrollBeyondLastLine: false,
                                        lineNumbers: 'on',
                                        automaticLayout: true,
                                        renderLineHighlight: 'none',
                                        renderLineHighlightOnlyWhenFocus: true,
                                    }}
                                />
                            </div>
                        }
                        rightPanel={
                            <div className="w-full h-full">
                                {error ? (
                                    <div
                                        className={`h-full overflow-auto p-4 font-mono text-sm ${
                                            isDarkMode ? 'bg-gray-800 text-gray-200' : 'bg-gray-50 text-gray-700'
                                        }`}
                                    >
                                        <div className="text-red-500 font-bold mb-2">Erreur de compilation :</div>
                                        <pre className="whitespace-pre-wrap">{error}</pre>
                                        {output && (
                                            <>
                                                <div className="text-red-500 font-bold mt-4 mb-2">Détails :</div>
                                                <pre className="whitespace-pre-wrap">{output}</pre>
                                            </>
                                        )}
                                    </div>
                                ) : pdfUrl ? (
                                    <PDFViewer pdfUrl={pdfUrl} isDarkMode={isDarkMode} />
                                ) : (
                                    <div
                                        className={`flex items-center justify-center h-full text-gray-500 ${
                                            isDarkMode ? 'bg-gray-800' : 'bg-gray-50'
                                        }`}
                                    >
                                        Compilez votre code pour voir le résultat
                                    </div>
                                )}
                            </div>
                        }
                        showLeftPanel={displayMode !== 'reader'}
                        showRightPanel={displayMode !== 'editor'}
                        isDarkMode={isDarkMode}
                        height={isExpandedView ? 'calc(100vh - 130px)' : '500px'}
                    />
                </div>

                {/* Barre de navigation inférieure */}
                <div className={`flex justify-between items-center mt-2 ${isExpandedView ? 'mt-auto py-0' : ''}`}>
                    <Button onClick={previousExercise} disabled={currentExercise === 0} variant="outline" size="sm">
                        <ChevronLeft className="mr-2 h-4 w-4" /> Précédent
                    </Button>
                    <div className="space-x-2">
                        <Button onClick={toggleEnonceSolution} variant="outline" size="sm">
                            <Code className="mr-2 h-4 w-4" />
                            {isEnonce ? 'Solution' : 'Énoncé'}
                        </Button>
                        <Button onClick={resetCurrentExercise} variant="outline" size="sm">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 mr-2"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
                                <path d="M3 3v5h5" />
                            </svg>
                            Réinitialiser
                        </Button>
                        <Button onClick={compileLaTeX} variant="outline" size="sm">
                            <Play className="mr-2 h-4 w-4" /> Compiler
                        </Button>
                    </div>
                    <Button
                        onClick={nextExercise}
                        disabled={currentExercise === latexExercises.length - 1}
                        variant="outline"
                        size="sm"
                    >
                        Suivant <ChevronRight className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            </div>
            <footer className={`mt-4 py-6 ${isDarkMode ? 'border-t border-gray-800' : 'border-t border-gray-200'}`}>
                <div className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    <p className="text-base text-center mb-4">
                        Pour vous accompagner dans votre apprentissage, découvrez ces excellentes ressources en ligne :
                    </p>
                    <div className="flex justify-center flex-wrap gap-4">
                        <a
                            href="https://www.learnlatex.org/fr/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`inline-flex items-center px-4 py-2 rounded-md transition-colors duration-200 ${
                                isDarkMode
                                    ? 'bg-gray-700 hover:bg-gray-600 text-gray-100'
                                    : 'bg-white hover:bg-gray-100 text-gray-700 border border-gray-200'
                            }`}
                        >
                            <span className="mr-2">Learn LaTeX</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                />
                            </svg>
                        </a>
                        <a
                            href="https://www.overleaf.com/learn/latex/Tutorials"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`inline-flex items-center px-4 py-2 rounded-md transition-colors duration-200 ${
                                isDarkMode
                                    ? 'bg-gray-700 hover:bg-gray-600 text-gray-100'
                                    : 'bg-white hover:bg-gray-100 text-gray-700 border border-gray-200'
                            }`}
                        >
                            <span className="mr-2">Tutoriels Overleaf</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                />
                            </svg>
                        </a>
                        <a
                            href="https://faq.gutenberg-asso.fr/index.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`inline-flex items-center px-4 py-2 rounded-md transition-colors duration-200 ${
                                isDarkMode
                                    ? 'bg-gray-700 hover:bg-gray-600 text-gray-100'
                                    : 'bg-white hover:bg-gray-100 text-gray-700 border border-gray-200'
                            }`}
                        >
                            <span className="mr-2">FAQ GUTenberg</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default App
