const latexExercises = [
    {
        id: 1,
        level: 1,
        title: 'Premier document LaTeX',
        description: 'Créer un document LaTeX simple avec un titre et du texte',
        initialCode: `% Objectifs :
% - Comprendre la structure minimale d'un document LaTeX
% - Utiliser les commandes \\title, \\author et \\date
% - Ajouter du texte simple en français
% - Compiler et visualiser le résultat

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

% Complétez le préambule avec :
% - Un titre
% - Un auteur
% - Une date

\\begin{document}

% Ajoutez \\maketitle ici

% Écrivez un court paragraphe de présentation
% en français (2-3 lignes suffisent)

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\title{Mon premier document LaTeX}
\\author{Votre Nom}
\\date{\\today}

\\begin{document}

\\maketitle

Voici mon premier document LaTeX. J'apprends à utiliser 
ce système de composition de documents. LaTeX permet de 
créer des documents professionnels de grande qualité.

\\end{document}`,
    },
    {
        id: 2,
        level: 1,
        title: 'Formatage de texte simple',
        description: 'Mettre en forme du texte avec gras, italique et souligné',
        initialCode: `% Objectifs :
% - Mettre du texte en gras avec \\textbf{...}
% - Mettre du texte en italique avec \\textit{...}
% - Utiliser plusieurs styles sur un même texte
% - Créer des paragraphes

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\begin{document}

% Créez trois paragraphes courts :
% 1. Un paragraphe avec du texte en gras
% 2. Un paragraphe avec du texte en italique
% 3. Un paragraphe combinant gras et italique

% N'oubliez pas de laisser une ligne vide
% entre chaque paragraphe

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{babel}

\\begin{document}

Voici un premier paragraphe avec du texte \\textbf{en gras}. 
Le gras permet de mettre en valeur certains mots.

Dans ce deuxième paragraphe, nous utilisons \\textit{l'italique} 
pour accentuer certains termes du texte.

Ce dernier paragraphe montre qu'il est possible de combiner 
\\textbf{\\textit{gras et italique}} pour un même texte, ou 
d'utiliser \\textbf{gras} et \\textit{italique} séparément.

\\end{document}`,
    },
    {
        id: 3,
        level: 1,
        title: 'Gestion des espaces',
        description: "Maîtriser les différents types d'espaces en LaTeX",
        initialCode: `% Objectifs :
% - Comprendre les différents types d'espaces horizontaux et verticaux
% - Utiliser \\, \\quad et \\qquad pour les espaces horizontaux
% - Gérer les sauts de ligne avec \\\\ et \\newline
% - Créer des paragraphes avec \\par
% - Ajouter des espaces verticaux avec \\vspace

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\begin{document}

% Créez un texte qui montre :
% 1. Les espaces horizontaux entre mots
% 2. Les espaces verticaux entre lignes
% 3. Les différences entre paragraphes
% 4. L'utilisation de \\vspace et \\hspace

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\begin{document}

Voici un texte normal suivi d'un petit espace\\,serré, 
puis d'un espace\\quad plus large\\qquad et encore plus large.

Cette ligne est suivie d'un retour à la ligne simple.\\\\
Et voici la suite.\\newline
Encore une nouvelle ligne.

Ceci est un nouveau paragraphe car il est séparé par une
ligne vide du précédent.\\par
Ceci est aussi un nouveau paragraphe créé avec \\textbackslash par.

\\vspace{1cm}
Ce texte est séparé du précédent par 1 cm.

\\vspace{5mm}
Et celui-ci par 5 mm.

Du texte avec \\hspace{2cm} un grand espace horizontal.

\\vspace{1cm}
Un dernier exemple :
% Création d'un centrage avec des espaces horizontaux
\\hspace*{\\fill}texte centré\\hspace*{\\fill}

\\end{document}`,
    },
    {
        id: 4,
        level: 1,
        title: 'Sections et paragraphes',
        description: 'Structurer son document avec des sections',
        initialCode: `% Objectifs :
% - Créer des sections avec \\section{...}
% - Créer des sous-sections avec \\subsection{...}
% - Organiser le texte en paragraphes
% - Comprendre la hiérarchie du document

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\begin{document}

% Créez un petit document sur un sujet de votre choix avec :
% - Au moins deux sections
% - Au moins une sous-section dans chaque section
% - Un court paragraphe dans chaque partie

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\begin{document}

\\section{Introduction}
Ce document présente les bases de LaTeX.

\\subsection{Objectifs}
Nous apprendrons à structurer un document avec des sections.

\\section{Structure du document}
La structure aide à organiser les idées.

\\subsection{Sections}
Les sections permettent de diviser le document en parties.

\\subsection{Sous-sections}
Les sous-sections permettent d'affiner la structure.

\\end{document}`,
    },
    {
        id: 5,
        level: 1,
        title: 'Listes simples',
        description: 'Créer des listes à puces et numérotées',
        initialCode: `% Objectifs :
% - Créer une liste à puces avec itemize
% - Créer une liste numérotée avec enumerate
% - Utiliser \\item pour chaque élément
% - Comprendre l'indentation des listes

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\begin{document}

% Créez :
% 1. Une liste à puces de 3 éléments
% 2. Une liste numérotée de 3 éléments
% Choisissez un thème simple (fruits, couleurs, etc.)

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

% \\frenchsetup{StandardItemLabels=true} % Décommentez cette ligne puis compilez à nouveau

\\begin{document}

Les fruits que j'aime :
\\begin{itemize}
  \\item Les pommes
  \\item Les bananes
  \\item Les oranges
\\end{itemize}

\\bigskip

Mon classement des couleurs :
\\begin{enumerate}
  \\item Rouge
  \\item Bleu
  \\item Vert
\\end{enumerate}

\\end{document}`,
    },
    {
        id: 6,
        level: 1,
        title: 'Tableaux simples',
        description: 'Créer des tableaux basiques avec alignements',
        initialCode: `% Objectifs :
% - Créer un tableau avec l'environnement tabular
% - Utiliser les alignements l, c et r
% - Ajouter des traits horizontaux avec \\hline
% - Comprendre la séparation des colonnes avec &

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\begin{document}

% Créez un emploi du temps simplifié sur 3 jours avec :
% - Une colonne pour les heures (alignée à droite)
% - Trois colonnes pour lundi, mardi, mercredi (centrées)
% - Des lignes de séparation horizontales
% - Au moins 3 créneaux horaires

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

% \\renewcommand{\\arraystretch}{1.5} % Décommentez cette ligne puis compilez à nouveau

\\begin{document}

\\begin{center}
\\begin{tabular}{|r|c|c|c|}
\\hline
Heures & Lundi & Mardi & Mercredi \\\\
\\hline
8h-10h & Maths & Français & EPS \\\\
10h-12h & Histoire & Physique & Anglais \\\\
14h-16h & Physique & Musique & Maths \\\\
\\hline
\\end{tabular}
\\end{center}

\\end{document}`,
    },
    {
        id: 7,
        level: 1,
        title: 'Mathématiques simples',
        description: 'Écrire des formules mathématiques basiques',
        initialCode: `% Objectifs :
% - Écrire des formules en ligne avec $...$
% - Écrire des formules centrées avec \\[...\\]
% - Utiliser les exposants (^) et indices (_)
% - Utiliser les fractions simples (\\frac{}{})

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}
\\usepackage{amssymb}

\\begin{document}

% Écrivez :
% 1. Une phrase contenant x² + 2x + 1 en ligne
% 2. L'équation du second degré ax² + bx + c = 0 centrée
% 3. Une fraction simple (par exemple 1/2) dans une phrase
% 4. Une formule avec un indice et l'ensemble ℝ (\\mathbb{R})

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}
\\usepackage{amssymb}

\\begin{document}

Le polynôme $x^2 + 2x + 1$ est un carré parfait.

La forme générale d'une équation du second degré à une inconnue est \\[ax^2 + bx + c = 0.\\]

La moitié d'un nombre s'écrit sous la forme $\\frac{n}{2}$. % Remplacez frac par dfrac puis recompilez

Sur $\\mathbb{R}$, la suite $(u_n)_{n \\in \\mathbb{N}}$ commence par le terme $u_0$.

\\end{document}`,
    },
    {
        id: 8,
        level: 1,
        title: 'Images simples',
        description: 'Insérer et positionner une image',
        initialCode: `% Objectifs :
% - Utiliser \\includegraphics pour insérer une image
% - Définir la largeur d'une image
% - Centrer une image
% - Ajouter une légende

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{graphicx}

\\begin{document}

% Insérez une image (example-image-a) :
% 1. Centrée dans la page
% 2. Avec une largeur de 8 cm
% 3. Avec une légende
% Note : example-image-a est une image de test disponible par défaut

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{graphicx}

\\begin{document}

\\begin{figure}[h]
    \\centering
    \\includegraphics[width=8cm]{example-image-a}
    \\caption{Une image de démonstration}
\\end{figure}

\\end{document}`,
    },
    {
        id: 9,
        level: 2,
        title: 'Commandes simples',
        description: 'Créer des commandes personnalisées basiques',
        initialCode: `% Objectifs :
% - Créer une commande simple avec \\newcommand
% - Utiliser un paramètre dans une commande
% - Comprendre l'intérêt des commandes personnalisées
% - Réutiliser une commande plusieurs fois

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{xcolor}

% Créez deux commandes dans le préambule :
% 1. \\important qui met le texte en gras et en rouge
% 2. \\terme qui met le texte en italique et ajoute des guillemets

\\begin{document}

% Écrivez un petit paragraphe utilisant 
% chaque commande au moins deux fois

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{xcolor}

\\newcommand{\\important}[1]{\\textbf{\\textcolor{red}{#1}}}
\\newcommand{\\terme}[1]{\\textit{\\og #1\\fg}}

\\begin{document}

Dans ce paragraphe, nous allons parler de \\terme{LaTeX}. 
Il est \\important{très important} de bien comprendre le 
\\terme{préambule} du document. Il est également 
\\important{essentiel} de pratiquer régulièrement.

\\end{document}`,
    },
    {
        id: 10,
        level: 2,
        title: "Création d'un tableau de notes avec mise en forme",
        description: 'Créer un tableau présentant des notes avec moyenne pondérée',
        initialCode: `% Objectif : Créer un tableau de notes avec :
% - Une présentation claire des matières, notes et coefficients
% - Utilisation des lignes de séparation de booktabs
% - Fusion de cellules pour la ligne de moyenne
% - Calcul de la moyenne pondérée

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{babel}
\\usepackage{booktabs}
\\begin{document}

% Créez un tableau contenant :
% - Une ligne d'en-tête avec les colonnes : Matière, Note, Coefficient
% - Au moins 3 matières avec leurs notes et coefficients
% - Une ligne de moyenne utilisant \\multicolumn
% - Les commandes \\toprule, \\midrule et \\bottomrule pour la mise en forme

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{multirow}
\\usepackage{booktabs}

\\begin{document}

\\begin{center}
\\begin{tabular}{lcc}
\\toprule
\\multicolumn{1}{c}{Matière} & Note & Coefficient \\\\
\\midrule
Mathématiques & 15 & 3 \\\\
Physique & 14 & 2 \\\\
Français & 13 & 2 \\\\
\\midrule
\\multicolumn{2}{l}{Moyenne} & 14,1 \\\\
\\bottomrule
\\end{tabular}
\\end{center}

\\end{document}`,
    },
    {
        id: 11,
        level: 2,
        title: 'Mathématiques avancées',
        description: 'Utiliser les environnements mathématiques align et cases',
        initialCode: `% Objectifs :
% - Utiliser l'environnement align pour aligner des équations
% - Créer des systèmes d'équations avec cases
% - Numéroter les équations automatiquement
% - Comprendre les différents modes mathématiques

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}
\\usepackage{amssymb}

\\begin{document}

% 1. Écrivez un système de deux équations avec cases
% 2. Résolvez une équation en alignant les étapes avec align
% 3. Incluez au moins une équation numérotée
% Par exemple : résoudre 2x + 3 = 8

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}
\\usepackage{amssymb}

\\begin{document}

Système d'équations :
\\[
\\begin{cases}
2x + y = 5 \\\\
x - y = 1
\\end{cases}
\\]

Résolution de l'équation $2x + 3 = 8$ :
\\begin{align}
2x + 3 &= 8 \\\\
2x + 3 - 3 &= 8 -3 \\\\
2x &= 5 \\\\
\\frac{2x}{2} &= \\frac{5}{2} \\\\
x &= \\frac{5}{2} \\label{eq:solution}
\\end{align}

La solution est donnée par l'équation (\\ref{eq:solution}).

% Remplacez align par align* puis recompilez

\\end{document}`,
    },
    {
        id: 12,
        level: 2,
        title: 'Listes personnalisées',
        description: "Personnaliser l'apparence des listes",
        initialCode: `% Objectifs :
% - Utiliser le package enumitem
% - Modifier l'espacement des listes
% - Changer le style des marqueurs
% - Créer une liste personnalisée avec label

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{enumitem}

\\begin{document}

% Créez trois listes courtes :
% 1. Une liste à puces avec espacement réduit
% 2. Une liste numérotée avec des lettres (a, b, c)
% 3. Une liste avec vos propres marqueurs personnalisés

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{enumitem}

\\begin{document}

Liste compacte :
\\begin{itemize}[noitemsep,topsep=0pt,label=$\\bullet$]
  \\item Premier point
  \\item Deuxième point
  \\item Troisième point
\\end{itemize}

\\bigskip

Liste alphabétique :
\\begin{enumerate}[itemsep=5pt,label=\\alph*)]
  \\item Premier élément
  \\item Deuxième élément
  \\item Troisième élément
\\end{enumerate}

\\bigskip

Liste personnalisée :
\\begin{itemize}[topsep=12pt,label=$\\star$]
  \\item Point important
  \\item Autre point
  \\item Dernier point
\\end{itemize}

\\end{document}`,
    },
    {
        id: 13,
        level: 2,
        title: 'Encadrements et boîtes',
        description: "Créer différents types d'encadrements",
        initialCode: `% Objectifs :
% - Utiliser \\fbox pour des encadrements simples
% - Créer des boîtes avec minipage
% - Gérer les espacements autour des boîtes
% - Comprendre les options de largeur

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\begin{document}

% Créez :
% 1. Un mot encadré simple avec \\fbox
% 2. Un paragraphe encadré avec minipage
% 3. Deux minipages côte à côte (50% de la largeur chacune)

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\begin{document}

Voici un \\fbox{mot important} dans une phrase.

\\begin{center}
\\fbox{%
  \\begin{minipage}{0.8\\textwidth}
    Un paragraphe complet dans une boîte. Le texte
    va automatiquement à la ligne quand nécessaire.
  \\end{minipage}
}
\\end{center}

\\noindent
\\begin{minipage}{0.48\\textwidth}
  Texte de la colonne de gauche.
  Il occupe la moitié de la largeur.
\\end{minipage}
\\hfill
\\begin{minipage}{0.48\\textwidth}
  Texte de la colonne de droite.
  Il occupe l'autre moitié.
\\end{minipage}

\\end{document}`,
    },
    {
        id: 14,
        level: 2,
        title: 'En-têtes et pieds de page',
        description: 'Personnaliser les en-têtes et pieds de page',
        initialCode: `% Objectifs :
% - Comprendre les en-têtes et pieds de page
% - Utiliser les commandes \\lhead, \\chead, \\rhead
% - Utiliser les commandes \\lfoot, \\cfoot, \\rfoot
% - Ajouter des numéros de page

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{fancyhdr}

% Ajoutez la configuration de fancyhdr ici
\\pagestyle{fancy}
\\fancyhf{}  % Efface tous les champs

\\begin{document}

% Créez un document de deux pages avec :
% - Votre nom à gauche de l'en-tête (\\lhead)
% - La date à droite de l'en-tête (\\rhead)
% - Le numéro de page centré en bas (\\cfoot)
% Utilisez \\lipsum[1-8] pour générer du texte

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm,headheight=14.5pt]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{fancyhdr}
\\usepackage{lipsum}

\\pagestyle{fancy}
\\fancyhf{}
\\lhead{Jean Dupont}
\\rhead{\\today}
\\cfoot{\\thepage}
\\renewcommand{\\headrulewidth}{0.4pt}

\\begin{document}

\\section{Première section}
\\lipsum[1-4]

\\section{Deuxième section}
\\lipsum[5-8]

\\end{document}`,
    },
    {
        id: 15,
        level: 2,
        title: 'Environnements personnalisés',
        description: 'Créer des environnements pour le cours',
        initialCode: `% Objectifs :
% - Créer des environnements avec \\newenvironment
% - Comprendre les paramètres des environnements
% - Personnaliser l'apparence
% - Utiliser des compteurs

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsthm}

% Créez deux environnements :
% 1. 'remarque' pour les remarques importantes
% 2. 'exercice' numéroté automatiquement

\\begin{document}

% Utilisez chaque environnement au moins deux fois
% avec du contenu approprié pour un cours

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsthm}

\\theoremstyle{definition}
\\newtheorem{exercice}{Exercice}

\\newenvironment{remarque}
    {\\medskip\\noindent\\textbf{Remarque.} \\begin{itshape}}
    {\\end{itshape}\\medskip}

\\begin{document}

\\begin{exercice}
    Calculer la dérivée de $f(x) = x^2$.
\\end{exercice}

\\begin{remarque}
    Ne pas oublier la règle de dérivation des puissances.
\\end{remarque}

\\begin{exercice}
    Déterminer les variations de la fonction.
\\end{exercice}

\\begin{remarque}
    Penser à étudier le signe de la dérivée.
\\end{remarque}

\\end{document}`,
    },
    {
        id: 16,
        level: 2,
        title: 'Figures et sous-figures',
        description: 'Gérer les figures multiples et leurs légendes',
        initialCode: `% Objectifs :
% - Utiliser le package subcaption
% - Créer des sous-figures
% - Gérer les références croisées
% - Organiser plusieurs images

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{graphicx}
\\usepackage{subcaption}

\\begin{document}

% Créez une figure contenant :
% 1. Deux images côte à côte (example-image-a et example-image-b)
% 2. Une légende pour chaque image
% 3. Une légende globale
% 4. Des labels pour les références

% Ajoutez ensuite une phrase faisant référence aux figures

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{graphicx}
\\usepackage{subcaption}

\\begin{document}

\\begin{figure}
    \\centering
    \\begin{subfigure}[b]{0.45\\textwidth}
        \\centering
        \\includegraphics[width=\\textwidth]{example-image-a}
        \\caption{Première image}
        \\label{fig:sub1}
    \\end{subfigure}
    \\hfill
    \\begin{subfigure}[b]{0.45\\textwidth}
        \\centering
        \\includegraphics[width=\\textwidth]{example-image-b}
        \\caption{Deuxième image}
        \\label{fig:sub2}
    \\end{subfigure}
    \\caption{Deux images de démonstration}
    \\label{fig:test}
\\end{figure}

Comme on peut le voir sur la figure~\\ref{fig:sub1}, 
et en comparant avec la figure~\\ref{fig:sub2}...

\\end{document}`,
    },
    {
        id: 17,
        level: 2,
        title: 'Table des matières',
        description: 'Créer un document structuré avec table des matières',
        initialCode: `% Objectifs :
% - Générer une table des matières avec \\tableofcontents
% - Structurer un document avec sections et sous-sections
% - Utiliser \\maketitle pour la page de titre

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{babel}
\\usepackage{lipsum}

\\begin{document}
% Créez un document structuré avec :
% 1. Une page de titre (\\maketitle)
% 2. Une table des matières
% 3. Au moins trois sections
% 4. Quelques sous-sections
% Utilisez \\lipsum[1] pour le contenu des sections

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{tocloft}
\\usepackage{lipsum}

\\title{Document structuré}
\\author{Jean Dupont}
\\date{\\today}

\\begin{document}

\\maketitle
\\tableofcontents
\\newpage

\\section{Introduction}
\\lipsum[1]

\\section{Développement}
\\subsection{Première partie}
\\lipsum[1]

\\subsection{Deuxième partie}
\\lipsum[1]

\\section{Conclusion}
\\lipsum[1]

\\end{document}`,
    },
    {
        id: 18,
        level: 2,
        title: 'Listes imbriquées',
        description: `Créer des listes dans des listes et utiliser l'environnement description`,
        initialCode: `% Objectifs :
% - Imbriquer des listes (enumerate dans itemize et vice-versa)
% - Utiliser l'environnement description
% - Gérer les espacements entre les niveaux
% - Comprendre la numérotation multiniveau

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{enumitem}

\\begin{document}

% Créez :
% 1. Une liste à puces contenant une liste numérotée
% 2. Une liste description pour définir 3 termes mathématiques
% 3. Une liste numérotée avec une sous-liste à puces

% Utilisez enumitem pour ajuster les espacements

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{enumitem}

\\begin{document}

\\subsection*{Concepts mathématiques}

\\begin{description}
    \\item[Définition] Un nombre premier est un entier naturel qui a exactement deux diviseurs.
    \\item[Théorème] Dans un triangle rectangle, le carré de l'hypoténuse est égal à la somme des carrés des deux autres côtés.
    \\item[Corollaire] La réciproque du théorème de Pythagore est vraie.
\\end{description}

\\subsection*{Chapitres à étudier}

\\begin{itemize}
    \\item Arithmétique
        \\begin{enumerate}
            \\item Divisibilité
            \\item Nombres premiers
            \\item PGCD et PPCM
        \\end{enumerate}
    \\item Géométrie
        \\begin{enumerate}
            \\item Triangles
            \\item Cercles
            \\item Théorème de Pythagore
        \\end{enumerate}
\\end{itemize}

Plan du cours :
\\begin{enumerate}
    \\item Introduction
        \\begin{itemize}
            \\item Rappels
            \\item Prérequis
        \\end{itemize}
    \\item Développement
        \\begin{itemize}
            \\item Théorèmes principaux
            \\item Applications
        \\end{itemize}
\\end{enumerate}

\\end{document}`,
    },
    {
        id: 19,
        level: 2,
        title: 'Code informatique',
        description: 'Présenter du code informatique avec coloration syntaxique',
        initialCode: `% Objectifs :
% - Utiliser listings pour du code en Python
% - Configurer la coloration syntaxique
% - Gérer les accents dans les commentaires
% - Numéroter les lignes de code

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{listings}
\\usepackage{xcolor}

% Ajoutez la configuration de listings pour Python
% avec gestion des accents et coloration

\\begin{document}

% Présentez une fonction Python simple avec :
% 1. Des commentaires en français
% 2. La numérotation des lignes
% 3. Une explication avant le code
% 4. Un exemple d'utilisation après

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{listings}
\\usepackage[svgnames]{xcolor}

\\lstset{
    language=Python,
    basicstyle=\\ttfamily\\small,
    keywordstyle=\\color{MediumBlue},
    stringstyle=\\color{Crimson},
    commentstyle=\\color{ForestGreen},
    numbers=left,
    numberstyle=\\tiny,
    numbersep=10pt,
    breaklines=true,
    showstringspaces=false,
    % frame=single, % Décommentez cette ligne puis recompilez
    literate=
        {é}{{\\'{e}}}1 {è}{{\\\`{e}}}1
        {à}{{\\\`{a}}}1 {ê}{{\\^{e}}}1
}

\\begin{document}

\\subsection*{Définition d'une fonction en Python}

\\begin{lstlisting}
def calculer_moyenne(nombres):
    """Calcule la moyenne d'une liste de nombres.
    
    Args:
        nombres: Liste des nombres à moyenner
    Returns:
        float: La moyenne calculée
    """
    # Vérification de la liste vide
    if len(nombres) == 0:
        return 0
        
    # Calcul de la moyenne
    somme = sum(nombres)
    moyenne = somme / len(nombres)
    return moyenne
\\end{lstlisting}

\\subsection*{Appel de la fonction}

\\begin{lstlisting}
# Test de la fonction
notes = [12, 15, 8, 17]
moyenne = calculer_moyenne(notes)
print(f"La moyenne est {moyenne}")  # Affiche 13.0
\\end{lstlisting}

\\end{document}`,
    },
    {
        id: 20,
        level: 2,
        title: "Boîtes pour fiche d'exercices",
        description: "Créer une fiche d'exercices avec des boîtes numérotées et non numérotées",
        initialCode: `% Objectifs :
% - Créer des environnements d'exercices numérotés avec newtcbtheorem
% - Créer des boîtes non numérotées pour méthodes et remarques
% - Gérer la numérotation automatique
% - Organiser une fiche d'exercices cohérente

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage[most]{tcolorbox}

% Créez :
% 1. Un environnement 'exercice' numéroté (vert clair)
% 2. Un environnement 'correction' numéroté (même numéro, bleu clair)
% 3. Une boîte 'methode' non numérotée (orange clair)
% 4. Une boîte 'remarque' non numérotée (rouge clair)

\\begin{document}

% Réalisez une fiche avec :
% - Un encadré méthode
% - Deux exercices avec leurs corrections
% - Une remarque importante
% Les exercices doivent être numérotés automatiquement
% Les corrections doivent porter le même numéro que l'exercice

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage[most]{tcolorbox}
\\usepackage{amsmath}

% Exercices et corrections numérotés
\\newtcbtheorem[auto counter]{exercice}{Exercice}
{colback=green!5,colframe=green!35!black,fonttitle=\\bfseries,separator sign=~\\textemdash{}}{ex}

\\newtcbtheorem[use counter from=exercice]{correction}{Correction exercice}
{colback=blue!5,colframe=blue!35!black,fonttitle=\\bfseries,separator sign=~\\textemdash{}}{cor}

% Boîtes non numérotées
\\newtcolorbox{methode}{
    enhanced,
    title=Méthode,
    colback=orange!5,
    colframe=orange!35!black,
    fonttitle=\\bfseries,
    attach boxed title to top left={yshift=-2mm},
    boxed title style={colback=orange!35!black}
}

\\newtcolorbox{remarque}{
    enhanced,
    title=Remarque,
    colback=red!5,
    colframe=red!35!black,
    fonttitle=\\bfseries,
    attach boxed title to top left={yshift=-2mm},
    boxed title style={colback=red!35!black}
}

\\begin{document}

\\begin{methode}
Pour calculer la dérivée d'un produit de fonctions $f$ et $g$, on utilise la formule :
\\[ (f\\times g)' = f'\\times g + f\\times g' \\]
\\end{methode}

\\begin{exercice}{Dérivée d'un produit}{}
Calculer la dérivée de la fonction $f$ définie par :
\\[ f(x) = x^2\\cos(x) \\]
\\end{exercice}

\\begin{correction}{Dérivée d'un produit}{}
On applique la formule de dérivation d'un produit :
\\begin{align*}
f'(x) &= (x^2)'\\cos(x) + x^2(\\cos(x))' \\\\
      &= 2x\\cos(x) + x^2(-\\sin(x)) \\\\
      &= 2x\\cos(x) - x^2\\sin(x)
\\end{align*}
\\end{correction}

\\begin{exercice}{Étude de variations}{}
Déterminer les variations de la fonction $f$ sur $[0,\\pi]$.
\\end{exercice}

\\begin{correction}{Étude de variations}{}
On étudie le signe de $f'(x)$ sur $[0,\\pi]$ :
\\begin{itemize}
  \\item $f'(x) = 2x\\cos(x) - x^2\\sin(x)$
  \\item Étude complexe, on pourrait factoriser...
\\end{itemize}
\\end{correction}

\\begin{remarque}
La dérivée d'un produit est souvent plus complexe qu'elle n'en a l'air !
Il faut bien penser à distribuer les signes moins quand on dérive cos(x).
\\end{remarque}

\\end{document}`,
    },
    {
        id: 21,
        level: 2,
        title: 'Texte autour des figures',
        description: 'Intégrer des images dans le texte avec wrapfig',
        initialCode: `% Objectifs :
% - Utiliser wrapfig pour insérer des images dans le texte
% - Faire s'adapter le texte autour des images
% - Contrôler la position et la taille des images
% - Ajouter des légendes

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{wrapfig}
\\usepackage{graphicx}
\\usepackage{lipsum}

\\begin{document}

% Créez un document avec :
% 1. Une image à droite du texte
% 2. Une image à gauche du texte
% 3. Chaque image doit avoir une légende
% Utilisez example-image-a et example-image-b

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{wrapfig}
\\usepackage{graphicx}
\\usepackage{lipsum}

\\begin{document}

\\begin{wrapfigure}{r}{0.5\\textwidth}
    \\centering
    \\includegraphics[width=0.45\\textwidth]{example-image-a}
    \\caption{Image de droite}
\\end{wrapfigure}

\\lipsum[1]

\\begin{wrapfigure}[10]{l}{0.4\\textwidth} % 10 est le nombre de lignes (paramètre optionnel)
    \\centering
    \\includegraphics[width=0.25\\textwidth]{example-image-b}
    \\caption{Image de gauche}
\\end{wrapfigure}

\\lipsum[2]

\\lipsum[3]

\\end{document}`,
    },
    {
        id: 22,
        level: 2,
        title: 'Références croisées',
        description: 'Créer et utiliser des références dans le document',
        initialCode: `% Objectifs :
% - Utiliser \\label pour marquer des éléments
% - Référencer avec \\ref et \\pageref
% - Comprendre les différents types d'éléments référençables
% - Créer des références claires et utiles

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{graphicx}
\\usepackage{amsmath}

\\begin{document}

% Créez un document contenant :
% 1. Deux sections avec labels
% 2. Une figure avec label
% 3. Une équation avec label
% 4. Un paragraphe qui fait référence à tous ces éléments
% N'oubliez pas de compiler deux fois !

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{graphicx}
\\usepackage{amsmath}

\\begin{document}

\\section{Introduction}
\\label{sec:intro}
Voici l'introduction de notre document.

\\section{Développement}
\\label{sec:dev}
Dans cette section, nous allons étudier une formule importante.

\\begin{equation}
\\label{eq:pythagore}
a^2 + b^2 = c^2
\\end{equation}

\\begin{figure}[h]
    \\centering
    \\includegraphics[width=6cm]{example-image-a}
    \\caption{Un triangle rectangle}
    \\label{fig:triangle}
\\end{figure}

Comme nous l'avons vu dans la section~\\ref{sec:intro} à la
page~\\pageref{sec:intro}, ce document présente plusieurs éléments.
L'équation~\\ref{eq:pythagore} illustre le théorème de Pythagore,
qui est représenté sur la figure~\\ref{fig:triangle}. Plus de 
détails sont donnés section~\\ref{sec:dev}.

\\end{document}`,
    },
    {
        id: 23,
        level: 2,
        title: 'Personnalisation des sections',
        description: "Modifier l'apparence des titres avec le paquet titlesec",
        initialCode: `% Documentation titlesec : https://distrib-coffee.ipsl.jussieu.fr/pub/mirrors/ctan/macros/latex/contrib/titlesec/titlesec.pdf

% Objectifs :
% - Utiliser titlesec pour personnaliser les titres
% - Modifier la police et les couleurs
% - Ajouter des décorations
% - Créer un style cohérent

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{titlesec}
\\usepackage{xcolor}

% Configurez l'apparence des sections et sous-sections
% en utilisant \\titleformat

\\begin{document}

% Créez plusieurs sections et sous-sections
% pour tester vos styles
% Incluez du texte entre les sections

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{titlesec}
\\usepackage{xcolor}

% \\renewcommand{\\thesection}{\\Roman{section}}
% \\renewcommand{\\thesubsection}{\\arabic{subsection}}
% \\renewcommand{\\thesubsubsection}{\\alph{subsubsection}}

\\titleformat{\\section}
    {\\normalfont\\Large\\bfseries\\color{blue}}
    {\\thesection.}{1em}{}[\\titlerule]

\\titleformat{\\subsection}
    {\\normalfont\\large\\bfseries\\color{blue!60}}
    {\\thesubsection/}{0.75em}{}

\\titleformat{\\subsubsection}
    {\\normalfont\\large\\bfseries\\color{orange!75}}
    {\\thesubsubsection)}{0.5em}{}

\\titlespacing*{\\section}
    {0pt}{3.5ex plus 1ex minus .2ex}{2.3ex plus .2ex}

\\begin{document}

\\section{Introduction}
Cette section utilise le nouveau style.

\\subsection{Contexte}
Les sous-sections sont également personnalisées.

\\subsubsection{Première application}
une sous-sous-section.

\\subsubsection{Deuxième application}
Une autre sous-sous-section.

\\section{Méthodologie}
Une autre section pour montrer le style.

\\subsection{Protocole}
Avec une autre sous-section.

\\subsubsection{Première application}
une sous-sous-section.

\\subsubsection{Deuxième application}
Une autre sous-sous-section.

\\end{document}`,
    },
    {
        id: 24,
        level: 2,
        title: 'Graphiques simples',
        description: 'Créer des graphiques basiques avec pgfplots',
        initialCode: `% Objectifs :
% - Utiliser pgfplots pour tracer des fonctions
% - Personnaliser les axes et la grille
% - Ajouter des légendes
% - Comprendre les options de base

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{pgfplots}
\\pgfplotsset{compat=1.18}

\\begin{document}

% Créez un graphique montrant :
% 1. La fonction f(x) = x² sur [-2,2]
% 2. Des axes avec flèches
% 3. Une grille
% 4. Des graduations tous les 0.5
% Ajoutez un titre et une légende

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{pgfplots}
\\pgfplotsset{compat=1.18}

\\begin{document}

\\begin{center}
\\begin{tikzpicture}
\\begin{axis}[
    title={Représentation de $f(x)=x^2$},
    xlabel={$x$},
    ylabel={$y$},
    xmin=-2, xmax=2,
    ymin=0, ymax=4,
    xtick distance=0.5,
    ytick distance=0.5,
    grid=both,
    grid style={line width=.1pt, draw=gray!10},
    major grid style={line width=.2pt,draw=gray!50},
    axis lines=middle,
    legend pos=north west
]
\\addplot[
    blue,
    domain=-2:2,
    samples=100,
    smooth
] {x^2};
\\legend{$f(x)=x^2$}
\\end{axis}
\\end{tikzpicture}
\\end{center}

\\end{document}`,
    },
    {
        id: 25,
        level: 2,
        title: "Création d'examens",
        description: 'Utiliser la classe exam pour créer des interrogations',
        initialCode: `% Objectifs :
% - Utiliser la classe exam
% - Créer différents types de questions
% - Gérer le barème
% - Prévoir l'espace de réponse

\\documentclass[12pt,french,addpoints]{exam}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}

% Configurez l'apparence de l'examen
\\qformat{\\textbf{Question \\thequestion}\\hfill[\\thepoints]}

\\begin{document}

% Créez un petit contrôle avec :
% 1. Une en-tête pour le nom
% 2. Trois questions de types différents
% 3. Des points pour chaque question
% 4. Des espaces de réponse adaptés

\\end{document}`,
        solution: `\\documentclass[12pt,french,addpoints]{exam}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}
\\usepackage{amssymb}

\\qformat{\\textbf{Question \\thequestion}\\hfill[\\thepoints]}
\\bracketedpoints

\\begin{document}

\\begin{center}
\\textbf{Contrôle de mathématiques}\\\\
Durée : 30 minutes

\\vspace{1cm}

Nom : \\underline{\\hspace{8cm}}
\\end{center}

\\vspace{1cm}

\\begin{questions}

\\question[4]
Résoudre dans $\\mathbb{R}$ l'équation suivante :
\\[ x^2 - 4 = 0 \\]
\\vspace{5cm}

\\question[3]
Calculer la dérivée de la fonction $f$ définie par :
\\[ f(x) = x^2 + 2x - 1 \\]
\\vspace{3cm}

\\question[3]
Déterminer les variations de la fonction $f$ sur $\\mathbb{R}$.
\\vspace{4cm}

\\end{questions}

\\vspace{1cm}
\\begin{center}
Total des points : \\numpoints
\\end{center}

\\end{document}`,
    },
    {
        id: 26,
        level: 2,
        title: 'Exercices avec xsim',
        description: 'Créer des exercices et leurs solutions avec xsim',
        initialCode: `% Objectifs :
% - Utiliser xsim pour créer des exercices
% - Gérer les solutions séparément
% - Personnaliser l'apparence des exercices
% - Créer deux versions du document

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{xsim}

% Configuration de base de xsim
\\xsimsetup{
    exercise/template = default,
    solution/template = default,
    exercise/name = Exercice,
    solution/name = Solution,
    solution/print = true  % false pour version élève
}

\\begin{document}

% Créez trois exercices types de lycée :
% 1. Une équation à résoudre
% 2. Un calcul de dérivée
% 3. Une étude de fonction
% Chacun avec sa solution

% Puis montrez comment obtenir :
% - La version élève (sans solutions)
% - La version professeur (avec solutions)

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{xsim}
\\usepackage{amsmath}
\\usepackage{amssymb}

\\xsimsetup{
    exercise/template = default,
    solution/template = default,
    exercise/name = Exercice,
    solution/name = Solution,
    solution/print = true  % false pour version élève
}

\\begin{document}

\\begin{exercise}[points=4,subtitle={Équation}]
Résoudre dans $\\mathbb{R}$ l'équation :
\\[ x^2 - 5x + 6 = 0 \\]
\\end{exercise}
\\begin{solution}
\\begin{align*}
x^2 - 5x + 6 &= 0\\\\
(x-2)(x-3) &= 0\\\\
x &= 2 \\text{ ou } x = 3
\\end{align*}
\\end{solution}

\\begin{exercise}[points=3,subtitle={Dérivée}]
Calculer la dérivée de $f(x) = x^2e^x$.
\\end{exercise}
\\begin{solution}
\\begin{align*}
f'(x) &= (x^2)'e^x + x^2(e^x)'\\\\
      &= 2xe^x + x^2e^x\\\\
      &= e^x(2x + x^2)
\\end{align*}
\\end{solution}

\\begin{exercise}[points=6,subtitle={Étude de fonction}]
Étudier les variations de la fonction $f$ sur $\\mathbb{R}$.
\\end{exercise}
\\begin{solution}
1) Le domaine de définition est $\\mathbb{R}$\\\\
2) $f'(x) = e^x(2x + x^2)$\\\\
3) Étude du signe de $f'(x)$ :
\\begin{itemize}
    \\item $e^x > 0$ pour tout $x$
    \\item $2x + x^2$ s'annule pour $x=0$ et $x=-2$
\\end{itemize}
\\end{solution}

% Pour version élève :
% 1. Mettre solution/print = false dans xsimsetup
% 2. Recompiler

% Pour version prof avec solutions à la fin :
\\newpage
\\printsolutions

\\end{document}`,
    },
    {
        id: 27,
        level: 2,
        title: 'Équations alignées à gauche',
        description: 'Aligner les équations à gauche avec fleqn',
        initialCode: `% Objectifs :
% - Utiliser l'option fleqn
% - Contrôler l'indentation des équations
% - Maintenir l'alignement dans les démonstrations
% - Créer une mise en page cohérente

\\documentclass[12pt,french,fleqn]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}

% Définir l'indentation des équations
\\setlength{\\mathindent}{1cm}

\\begin{document}

% Créez une démonstration mathématique avec :
% 1. Des équations alignées à gauche
% 2. Des explications intercalées
% 3. Différents environnements mathématiques
% 4. Une présentation claire et lisible

\\end{document}`,
        solution: `\\documentclass[12pt,french,fleqn]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}

\\setlength{\\mathindent}{1cm}

\\begin{document}

\\section*{Démonstration du théorème de la dérivée composée}

Soit $f$ et $g$ deux fonctions dérivables, montrons que :
\\[
(f \\circ g)'(x) = f'(g(x)) \\times g'(x)
\\]

Par définition de la dérivée :
\\[
(f \\circ g)'(x) = \\lim_{h \\to 0} \\frac{f(g(x+h)) - f(g(x))}{h}
\\]

Posons $k = g(x+h) - g(x)$. Alors $k \\to 0$ quand $h \\to 0$ car $g$ est continue.

\\begin{align*}
(f \\circ g)'(x) &= \\lim_{h \\to 0} \\frac{f(g(x+h)) - f(g(x))}{h} \\\\
&= \\lim_{h \\to 0} \\frac{f(g(x+h)) - f(g(x))}{g(x+h) - g(x)} \\times \\frac{g(x+h) - g(x)}{h} \\\\
&= f'(g(x)) \\times g'(x)
\\end{align*}

Les limites existent car $f$ et $g$ sont dérivables.

\\end{document}`,
    },
    {
        id: 28,
        level: 2,
        title: 'Page de garde avancée',
        description: 'Créer une page de titre personnalisée',
        initialCode: `% Objectifs :
% - Créer une page de titre professionnelle
% - Utiliser des minipage pour la mise en page
% - Insérer un logo ou une image
% - Gérer les espacements

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{graphicx}

\\begin{document}

% Créez une page de titre avec :
% 1. Un grand titre
% 2. Un sous-titre
% 3. Les informations de l'auteur
% 4. Une image (example-image)
% 5. La date
% Utilisez des minipages pour la mise en page

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{graphicx}

\\begin{document}

\\begin{titlepage}
\\begin{center}

% Logo ou image en haut
\\includegraphics[width=5cm]{example-image}\\\\[2cm]

% Titre principal
{\\huge\\bfseries Titre du Document}\\\\[1cm]

% Sous-titre
{\\Large\\textit{Sous-titre explicatif}}\\\\[2cm]

% Informations auteur
\\begin{minipage}{0.4\\textwidth}
\\begin{flushleft}
\\large
\\textbf{Auteur :}\\\\
Jean Dupont\\\\
Professeur de mathématiques
\\end{flushleft}
\\end{minipage}
\\begin{minipage}{0.4\\textwidth}
\\begin{flushright}
\\large
\\textbf{Établissement :}\\\\
Lycée Example\\\\
Académie de Lyon
\\end{flushright}
\\end{minipage}

\\vfill

% Pied de page
{\\large Année scolaire 2024-2025}

\\end{center}
\\end{titlepage}

\\end{document}`,
    },
    {
        id: 29,
        level: 2,
        title: 'Tableaux de variations avec tkz-tab',
        description: 'Créer des tableaux de variations avec tkz-tab',
        initialCode: `% Objectifs :
% - Créer des tableaux de variations clairs
% - Gérer les flèches et les valeurs
% - Indiquer les extrema
% - Comprendre la structure ligne par ligne

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}
\\usepackage{amssymb}
\\usepackage{tkz-tab}

\\begin{document}

% Créez un tableau de variations pour la fonction
% f(x) = x² - 2x - 3 sur [-1,4] :
% 1. Créez la structure du tableau
% 2. Indiquez les variations (flèches)
% 3. Ajoutez les valeurs aux points importants

% Puis créez un second tableau de variations plus complexe
% pour g(x) = x³ - 3x sur [-2,2]

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}
\\usepackage{amssymb}
\\usepackage{tkz-tab}

\\begin{document}

\\section*{Tableau de variations de $f(x)=x^2-2x-3$}

\\begin{center}
\\begin{tikzpicture}
\\tkzTabInit{
  $x$   /1,
  $f'$  /1,
  $f$   /2%
}{%
  $-1$, $1$, $4$%
}
\\tkzTabLine{, -, z, +,}
\\tkzTabVar{-/$-4$, +/$-4$, -/$5$}
\\end{tikzpicture}
\\end{center}

\\vspace{1cm}

\\section*{Tableau de variations de $g(x)=x^3-3x$}

\\begin{center}
\\begin{tikzpicture}
\\tkzTabInit{
  $x$   /1,
  $g'$  /1,
  $g$   /2%
}{%
  $-2$, $-1$, $1$, $2$%
}
\\tkzTabLine{, +, z, -, z, +,}
\\tkzTabVar{+/ $2$, -/$2$, +/$0$, -/$-2$}
\\end{tikzpicture}
\\end{center}

% Points clés à noter :
% 1. tkzTabInit définit les lignes et les colonnes
% 2. tkzTabLine gère les signes de la dérivée
% 3. tkzTabVar gère les flèches et valeurs
% 4. Les virgules sont importantes pour la syntaxe

\\end{document}`,
    },
    {
        id: 30,
        level: 2,
        title: 'Présentation Beamer simple',
        description: 'Créer une présentation avec Beamer',
        initialCode: `% Objectifs :
% - Utiliser la classe beamer avec un thème simple
% - Créer des diapositives basiques
% - Gérer les listes progressives
% - Configurer l'apparence globale

\\documentclass[french]{beamer}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

% Configurez le thème Singapore avec le thème de couleur beaver
% Supprimez la barre de navigation et l'en-tête des sections

\\begin{document}

% Créez une présentation avec :
% 1. Une page de titre
% 2. Un plan
% 3. Une diapositive avec liste progressive
% 4. Une diapositive avec deux colonnes
% 5. Une conclusion

\\end{document}`,
        solution: `\\documentclass[french]{beamer}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\usetheme{Singapore}
\\usecolortheme{beaver}
\\setbeamertemplate{headline}{}
\\setbeamertemplate{navigation symbols}{}

\\title{Titre de la présentation}
\\author{Prénom Nom}
\\institute{Établissement}
\\date{\\today}

\\begin{document}

\\begin{frame}
\\titlepage
\\end{frame}

\\begin{frame}{Plan}
\\tableofcontents
\\end{frame}

\\section{Première partie}

\\begin{frame}{Liste progressive}
\\begin{itemize}[<+->]
    \\item Premier point
    \\item Deuxième point
    \\item Troisième point
        \\begin{itemize}
            \\item Sous-point A
            \\item Sous-point B
        \\end{itemize}
\\end{itemize}
\\end{frame}

\\begin{frame}{Deux colonnes}
\\begin{columns}
    \\begin{column}{0.5\\textwidth}
        \\textbf{Colonne gauche}
        \\begin{itemize}
            \\item Point 1
            \\item Point 2
        \\end{itemize}
    \\end{column}
    \\begin{column}{0.5\\textwidth}
        \\textbf{Colonne droite}
        \\begin{itemize}
            \\item Point 3
            \\item Point 4
        \\end{itemize}
    \\end{column}
\\end{columns}
\\end{frame}

\\section{Conclusion}

\\begin{frame}{Conclusion}
\\begin{block}{Points clés}
    \\begin{itemize}
        \\item Synthèse 1
        \\item Synthèse 2
    \\end{itemize}
\\end{block}
\\end{frame}

\\end{document}`,
    },
    {
        id: 31,
        level: 2,
        title: 'Fiches de révision en colonnes',
        description: 'Créer des fiches de révision avec plusieurs colonnes',
        initialCode: `% Objectifs :
% - Utiliser multicol pour créer des colonnes
% - Organiser efficacement l'information
% - Gérer les sauts de colonne
% - Créer une mise en page claire

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{multicol}
\\usepackage{amsmath}
\\usepackage{amssymb}

% Ajoutez d'autres packages utiles pour la mise en forme

\\begin{document}

% Créez une fiche de révision avec :
% 1. Un titre principal
% 2. Trois colonnes
% 3. Des formules importantes
% 4. Des points clés à retenir
% 5. Des exemples courts

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{multicol}
\\usepackage{amsmath}
\\usepackage{amssymb}
\\usepackage{tcolorbox}

\\newtcolorbox{formule}{
    colback=blue!5,
    colframe=blue!75!black,
    title=Formule,
    fonttitle=\\bfseries
}

\\begin{document}

\\begin{center}
    \\LARGE\\textbf{Fiche de révision : Dérivées}\\\\[1em]
    \\normalsize Formules essentielles et applications
\\end{center}

\\begin{multicols}{3}
\\section*{Formules de base}
\\begin{formule}
$(u+v)' = u' + v'$

$(uv)' = u'v + uv'$

$\\left(\\frac{u}{v}\\right)' = \\frac{u'v-uv'}{v^2}$
\\end{formule}

\\section*{Dérivées usuelles}
\\begin{itemize}
\\item $(x^n)' = nx^{n-1}$
\\item $(e^x)' = e^x$
\\item $(\\ln x)' = \\frac{1}{x}$
\\end{itemize}

\\columnbreak

\\section*{Applications}
\\textbf{Exemple 1:} $f(x)=x^2e^x$
\\begin{align*}
f'(x) &= 2xe^x + x^2e^x\\\\
      &= e^x(2x + x^2)
\\end{align*}

\\textbf{Exemple 2:} $g(x)=\\frac{x}{1+x}$
\\begin{align*}
g'(x) &= \\frac{1\\cdot(1+x)-x\\cdot1}{(1+x)^2}\\\\
      &= \\frac{1}{(1+x)^2}
\\end{align*}

\\columnbreak

\\section*{Points clés}
\\begin{enumerate}
\\item Bien identifier la forme de la fonction
\\item Appliquer la bonne formule
\\item Simplifier le résultat
\\end{enumerate}

\\section*{À retenir}
\\begin{itemize}
\\item Dérivée d'une somme
\\item Dérivée d'un produit
\\item Dérivée d'un quotient
\\item Dérivées usuelles
\\end{itemize}

\\end{multicols}

\\end{document}`,
    },
    {
        id: 32,
        level: 2,
        title: 'Unités scientifiques',
        description: 'Utiliser siunitx pour mesures et incertitudes',
        initialCode: `% Objectifs :
% - Utiliser siunitx pour les unités
% - Gérer les incertitudes de mesure
% - Créer des tableaux de mesures
% - Utiliser la notation scientifique

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage[separate-uncertainty=true]{siunitx}
\\usepackage{booktabs}

% Configuration pour le français
\\sisetup{
    output-decimal-marker = {,},
    list-final-separator = { \\text{ et }},
    range-phrase = { \\text{ à }},
    locale = FR
}

\\begin{document}

% Créez un document présentant :
% 1. Des mesures simples avec unités
% 2. Des mesures avec incertitudes
% 3. Un tableau de valeurs
% 4. Des conversions d'unités

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage[separate-uncertainty=true]{siunitx}
\\usepackage{booktabs}

\\sisetup{
    output-decimal-marker = {,},
    list-final-separator = { \\text{ et }},
    range-phrase = { \\text{ à }},
    locale = FR
}

\\begin{document}

\\section*{Mesures simples}

La résistance vaut \\SI{4,7}{\\kilo\\ohm}. La tension mesurée est 
\\SI{12}{\\volt} et l'intensité \\SI{2,5}{\\ampere}. La température 
ambiante est de \\SI{23}{\\celsius}.

\\section*{Mesures avec incertitudes}

\\begin{itemize}
\\item Masse : \\SI{125,3 +- 0,2}{\\gram}
\\item Longueur : \\SI{45,7 +- 0,1}{\\centi\\meter}
\\item Temps : \\SI{12,45 +- 0,05}{\\second}
\\end{itemize}

\\section*{Tableau de mesures}

\\begin{table}[htbp]
\\centering
\\begin{tabular}{
    S[table-format=2.1]
    S[table-format=2.2(2)]
    S[table-format=1.3e1]
}
\\toprule
{Temps (\\si{\\second})} & 
{Température (\\si{\\celsius})} & 
{Pression (\\si{\\pascal})} \\\\
\\midrule
0,0 & 20,00 \\pm 0,05 & 1,013e5 \\\\
5,0 & 22,15 \\pm 0,05 & 1,015e5 \\\\
10,0 & 24,30 \\pm 0,05 & 1,018e5 \\\\
\\bottomrule
\\end{tabular}
\\caption{Relevé de mesures expérimentales}
\\end{table}

\\section*{Conversions et unités composées}

\\begin{itemize}
\\item Vitesse : \\SI{72}{\\kilo\\metre\\per\\hour} = 
    \\SI{20}{\\metre\\per\\second}
\\item Accélération : \\SI{9,81}{\\metre\\per\\square\\second}
\\item Énergie : \\SI{1,5}{\\kilo\\watt\\hour} = 
    \\SI{5,4e6}{\\joule}
\\item Concentration : \\SI{0,1}{\\mole\\per\\cubic\\deci\\metre}
\\end{itemize}

\\section*{Notation scientifique}

\\begin{itemize}
\\item Charge élémentaire : \\SI{1,602e-19}{\\coulomb}
\\item Constante d'Avogadro : \\SI{6,022e23}{\\per\\mole}
\\item Constante de Planck : \\SI{6,626e-34}{\\joule\\second}
\\end{itemize}

\\end{document}`,
    },
    {
        id: 33,
        level: 2,
        title: 'Chimie : molécules et réactions',
        description: 'Représenter des molécules et des équations chimiques',
        initialCode: `% Objectifs :
% - Dessiner des molécules organiques avec chemfig
% - Écrire des équations chimiques avec mhchem
% - Représenter différents types de liaisons
% - Illustrer les réactions chimiques

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{chemfig}
\\usepackage[version=4]{mhchem}
\\usepackage{siunitx}

\\begin{document}

% 1. Représentez l'éthanol avec chemfig
% 2. Représentez le benzène avec chemfig
% 3. Écrivez la réaction de combustion de l'éthanol avec mhchem
% 4. Écrivez une réaction acido-basique impliquant H3O+ avec mhchem

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{chemfig}
\\usepackage[version=4]{mhchem}
\\usepackage{siunitx}

\\begin{document}

\\section*{Représentation des molécules}

\\subsection*{L'éthanol}
\\begin{center}
\\chemfig{H_3C-CH_2-OH}
\\end{center}

\\subsection*{Le benzène}
\\begin{center}
\\chemfig{*6(-=-=-=-=-=-)}
\\end{center}

\\section*{Équations chimiques}

\\subsection*{Combustion de l'éthanol}
\\begin{center}
\\ce{C2H5OH(l) + 3 O2(g) -> 2 CO2(g) + 3 H2O(g)}
\\end{center}

\\subsection*{Réaction acido-basique}
\\begin{center}
\\ce{H3O+(aq) + OH-(aq) -> 2 H2O(l)}
\\end{center}

\\section*{Réaction avec conditions}
\\begin{center}
\\ce{C2H5OH(l) + CH3COOH(l) <=>[H+][\\SI{80}{\\celsius}] CH3COOC2H5(l) + H2O(l)}
\\end{center}

\\end{document}`,
    },
    {
        id: 34,
        level: 2,
        title: 'Circuits électriques',
        description: 'Dessiner des circuits électriques simples',
        initialCode: `% Objectifs :
% - Dessiner des circuits électriques simples avec circuitikz
% - Créer un circuit en série avec générateur, lampe et résistance
% - Créer un circuit avec deux lampes en parallèle
% - Ajouter les tensions et intensités

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage[
  european resistor,
  RPvoltages,
  european current,
  european voltage,
  straightvoltages
]{circuitikz}
\\usetikzlibrary{babel}

\\begin{document}

% Créez deux circuits :
% 1. Un circuit série avec générateur, lampe et résistance
% 2. Un circuit avec deux lampes en parallèle 
% N'oubliez pas d'indiquer les tensions et intensités !

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage[
  european resistor,
  RPvoltages,
  european current,
  european voltage,
  straightvoltages
]{circuitikz}
\\usetikzlibrary{babel}

\\begin{document}

\\section*{Circuit en série}

\\begin{center}
\\begin{circuitikz}
\\draw (0,0) to [vsource, v=$U_G$, i=$I$] (4,0)
    to [lamp, v=$U_L$] (4,-3)
    to [R=$R_1$, v=$U_R$] (0,-3)
    to (0,0);
\\end{circuitikz}
\\end{center}

\\section*{Circuit avec lampes en parallèle}

\\begin{center}
\\begin{circuitikz}
\\draw (0,0) to [vsource, v=$U_G$] (4,0)
    to [lamp, i=$I_1$] (4,-3)
    to (0,-3)
    to (0,0);
\\draw (4,0) to (6,0)
    to [lamp, i=$I_2$] (6,-3)
    to (4,-3);
\\end{circuitikz}
\\end{center}

\\end{document}`,
    },
    {
        id: 35,
        level: 2,
        title: "Tableau d'avancement en Chimie",
        description: "Créer un tableau d'avancement avec tabularray",
        initialCode: `% Objectifs :
% - Utiliser le package tabularray
% - Ajouter des symboles de réaction
% - Gérer les équations chimiques complexes
% - Créer un tableau professionnel

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{tabularray}
\\usepackage{chemmacros}

\\begin{document}

% Créez un tableau d'avancement pour la réaction :
% N2 + 3 H2 -> 2 NH3
% en utilisant tabularray et chemmacros
% avec les flèches et + entre les colonnes

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{tabularray}
\\usepackage{chemmacros}

\\begin{document}
\\begin{tblr}{%
  vlines, hlines,
  colspec = {Q[c] Q[c] X[c] X[c] X[c]},
  vline{4} = {1}{ text = \\clap{\\ch{+}} },
  vline{5} = {1}{ text = \\clap{\\ch{->}} },
}
  État          & Avancement & \\ch{N2}      & \\ch{3 H2}     & \\ch{2 NH3} \\\\
  Initial       & $x=0$      & $n_{\\ch{N2}}$   & $n_{\\ch{H2}}$       & 0         \\\\
  Intermédiaire & $x$        & $n_{\\ch{N2}} - x$ & $n_{\\ch{H2}} - 3x$  & $2x$      \\\\
  Final         & $x_f$      & $n_{\\ch{N2}} - x_f$ & $n_{\\ch{H2}} - 3x_f$ & $2x_f$   \\\\
\\end{tblr}
\\end{document}`,
    },
    {
        id: 36,
        level: 2,
        title: 'Vecteurs en Mathématiques et Physique',
        description: 'Représenter des vecteurs selon les conventions',
        initialCode: `% Objectifs :
% - Utiliser différentes notations de vecteurs (maths/physique)
% - Créer des commandes personnalisées pour les vecteurs
% - Utiliser le package physics pour la physique
% - Représenter graphiquement les vecteurs avec TikZ

\\documentclass[12pt,french]{article}
\\usepackage[margin=1.5cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{tikz}
\\usepackage{physics}  % Pour la notation physique
\\usetikzlibrary{arrows.meta,babel}

% Style pour les vecteurs dans les diagrammes
\\tikzset{vector/.style={-{Latex[length=3mm]},thick,blue}}

% Pour les mathématiques (avec flèche)
\\newcommand{\\vectm}[1]{\\overrightarrow{#1}}

% Pour la physique (en gras)
\\newcommand{\\vectp}[1]{\\mathbf{#1}}

\\begin{document}

% Créez trois sections :
% 1. Notation mathématique : \\vectm{u}, \\vectm{AB}
% 2. Notation physique personnalisée : \\vectp{F}, \\vectp{v}
% 3. Notation avec physics : \\vb{F}, \\vb{P}

% Illustrez chaque cas avec un diagramme TikZ approprié

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=1.5cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{tikz}
\\usepackage{physics}
\\usetikzlibrary{arrows.meta,babel}

\\tikzset{vector/.style={-{Latex[length=3mm]},thick,blue}}

\\newcommand{\\vectm}[1]{\\overrightarrow{#1}}
\\newcommand{\\vectp}[1]{\\mathbf{#1}}

\\begin{document}

\\section*{Notation en mathématiques}

Soit $\\vectm{u}$ un vecteur du plan et $\\vectm{AB}$ le vecteur associé aux points A et B.

\\begin{center}
\\begin{tikzpicture}[scale=1.5]
    % Axes
    \\draw[->] (-0.5,0) -- (4,0) node[right] {$x$};
    \\draw[->] (0,-0.5) -- (0,3) node[above] {$y$};
    
    % Vecteur avec flèche (style maths)
    \\draw[vector] (0,0) -- (3,2) node[above right] {$\\vectm{u}$};
    
    % Points pour vecteur AB
    \\fill (1,1) circle (1pt) node[below left] {A};
    \\fill (2,2) circle (1pt) node[above right] {B};
    \\draw[vector] (1,1) -- (2,2) node[midway, above left] {$\\vectm{AB}$};
\\end{tikzpicture}
\\end{center}

\\section*{Notation en physique (avec commande personnalisée)}

La force $\\vectp{F}$ et la vitesse $\\vectp{v}$ sont représentées en gras.

\\begin{center}
\\begin{tikzpicture}[scale=1.5]
    % Point matériel
    \\fill (0,0) circle (2pt) node[below right] {M};
    
    % Forces en gras
    \\draw[vector] (0,0) -- (2,0) node[midway, above] {$\\vectp{F}$};
    \\draw[vector] (0,0) -- (1,1) node[above right] {$\\vectp{v}$};
\\end{tikzpicture}
\\end{center}

\\section*{Notation avec le package physics}

Le poids $\\vb{P}$ et la tension $\\vb{T}$ utilisant le package physics.

\\begin{center}
\\begin{tikzpicture}[scale=1.5]
    % Point matériel
    \\fill (0,0) circle (2pt) node[above left] {M};
    
    % Forces avec physics
    \\draw[vector] (0,0) -- (0,-2) node[below] {$\\vb{P}$};
    \\draw[vector] (0,0) -- (2,1) node[right] {$\\vb{T}$};
\\end{tikzpicture}
\\end{center}

\\section*{Comparaison des notations}
\\begin{itemize}
\\item Maths : $\\vectm{u}$, $\\vectm{AB}$ (avec flèche)
\\item Physique (perso) : $\\vectp{F}$, $\\vectp{v}$ (en gras)
\\item Physique (physics) : $\\vb{F}$, $\\vb{P}$ (en gras avec physics)
\\end{itemize}

\\end{document}`,
    },
    {
        id: 37,
        level: 3,
        title: 'Formulaires PDF',
        description: 'Créer des formulaires interactifs avec hyperref',
        initialCode: `% Objectifs :
% - Créer des champs de saisie
% - Ajouter des cases à cocher
% - Gérer les zones de texte multiligne

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage[hidelinks]{hyperref}

\\begin{document}

% Créez un formulaire d'inscription avec :
% 1. Informations personnelles
% 2. Choix multiples
% 3. Zone de commentaire

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage[hidelinks]{hyperref}

% Téléchargez le pdf puis ouvrez-le dans un éditeur supportant les formulaires

\\begin{document}

\\begin{Form}

\\section*{Formulaire d'inscription}

\\subsection*{Informations personnelles}
\\begin{description}
\\item[Nom :] \\TextField[name=nom, width=12cm]{}\\\\[0.3cm]
\\item[Prénom :] \\TextField[name=prenom, width=12cm]{}\\\\[0.3cm]
\\item[Date de naissance :] \\TextField[name=naissance, width=4cm]{}\\\\[0.3cm]
\\item[Email :] \\TextField[name=email, width=12cm]{}
\\end{description}

\\subsection*{Niveau scolaire}
\\begin{itemize}
\\item \\CheckBox[name=seconde]{} Seconde
\\item \\CheckBox[name=premiere]{} Première
\\item \\CheckBox[name=terminale]{} Terminale
\\end{itemize}

\\subsection*{Spécialités choisies}
\\begin{itemize}
\\item \\CheckBox[name=maths]{} Mathématiques
\\item \\CheckBox[name=physique]{} Physique-Chimie
\\item \\CheckBox[name=svt]{} SVT
\\item \\CheckBox[name=nsi]{} NSI
\\end{itemize}

\\subsection*{Options}
\\begin{itemize}
\\item \\CheckBox[name=euro]{} Section européenne
\\item \\CheckBox[name=latin]{} Latin
\\item \\CheckBox[name=theatre]{} Théâtre
\\end{itemize}

\\subsection*{Commentaires}
\\begin{center}
\\TextField[name=commentaires, width=15cm, height=5cm, multiline=true]
{}
\\end{center}

\\end{Form}

% Notes importantes:
% 1. Compiler avec pdflatex
% 2. Les champs sont interactifs dans le PDF
% 3. La mise en page peut varier selon le lecteur PDF

\\end{document}`,
    },
    {
        id: 38,
        level: 3,
        title: 'Sujets différenciés',
        description: "Créer des versions adaptées d'un même sujet",
        initialCode: `% Objectifs :
% - Créer plusieurs versions d'un même devoir
% - Adapter le contenu et les aides selon le niveau
% - Gérer les points et le barème de manière cohérente
% - Maintenir une base commune tout en différenciant

\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}
\\usepackage{amssymb}
\\usepackage{tcolorbox}
\\usepackage{xstring}

% Définir la version (A, B ou C)
\\newcommand{\\version}{A}

% Créez les commandes et environnements nécessaires
% pour gérer les différentes versions

\\begin{document}

% Créez un devoir avec :
% 1. Une partie commune aux trois versions
% 2. Des exercices adaptés selon le niveau
% 3. Des aides progressives
% 4. Un barème différencié

\\end{document}`,
        solution: `\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage{amsmath}
\\usepackage{amssymb}
\\usepackage{tcolorbox}
\\usepackage{xstring}

% Configuration de la version
\\newcommand{\\version}{A}  % Changer pour B ou C

% Paramètres selon la version
\\IfEqCase{\\version}{%
    {A}{\\newcommand{\\niveau}{Standard}
        \\newcommand{\\notemax}{20}
        \\newcommand{\\tempsmax}{1h}
        \\newcommand{\\afficheraides}{true}}%
    {B}{\\newcommand{\\niveau}{Approfondi}
        \\newcommand{\\notemax}{22}
        \\newcommand{\\tempsmax}{1h}
        \\newcommand{\\afficheraides}{false}}%
    {C}{\\newcommand{\\niveau}{Expert}
        \\newcommand{\\notemax}{25}
        \\newcommand{\\tempsmax}{1h}
        \\newcommand{\\afficheraides}{false}}%
}

% Définition des environnements
\\newtcolorbox{aide}{
    colback=yellow!5,
    colframe=yellow!35!black,
    title=Aide,
    fonttitle=\\bfseries
}

\\newtcolorbox{methode}{
    colback=blue!5,
    colframe=blue!35!black,
    title=Méthode,
    fonttitle=\\bfseries
}

\\begin{document}

\\begin{center}
\\Large\\textbf{Contrôle de mathématiques}\\\\[0.3cm]
\\normalsize
Version \\version{} -- Niveau \\niveau{}\\\\
Durée : \\tempsmax{} -- Note sur \\notemax{}
\\end{center}

\\section*{Exercice 1 -- Partie commune (8 points)}

Résoudre dans $\\mathbb{R}$ l'équation suivante :
\\[ x^2 - 5x + 6 = 0 \\]

\\IfEqCase{\\version}{%
    {A}{\\begin{aide}
        1) On peut factoriser cette expression\\\\
        2) Cherchez deux nombres qui multipliés donnent 6\\\\
           et qui additionnés donnent -5
        \\end{aide}}%
    {B}{\\begin{aide}
        Pensez à la forme factorisée $(x-a)(x-b)$
        \\end{aide}}%
    {C}{} % Pas d'aide pour la version C
}

\\section*{Exercice 2 -- Partie différenciée}

\\IfEqCase{\\version}{%
    {A}{(6 points) Calculer la dérivée de $f(x) = x^2e^x$ 
        \\begin{aide}
        Utilisez la formule de dérivation d'un produit :
        $(uv)' = u'v + uv'$
        \\end{aide}}%
    {B}{(7 points) Étudier les variations de $f(x) = x^2e^x$ sur $\\mathbb{R}$
        \\begin{methode}
        1) Calculer $f'(x)$\\\\
        2) Étudier le signe de $f'(x)$\\\\
        3) En déduire le tableau de variations
        \\end{methode}}%
    {C}{(8 points) Étudier les variations de $f(x) = x^2e^x$ sur $\\mathbb{R}$
        et déterminer ses extremums}%
}

\\section*{Exercice 3 -- Bonus}

\\IfEqCase{\\version}{%
    {A}{(6 points) Tracer la courbe représentative de $f$ sur $[-2,2]$}%
    {B}{(7 points) Tracer la courbe représentative de $f$ et sa tangente
        au point d'abscisse 0}%
    {C}{(9 points) Tracer la courbe représentative de $f$ et déterminer
        l'aire sous la courbe entre 0 et 1}%
}

\\vspace{1cm}
\\begin{center}
    Total : \\notemax{} points
\\end{center}

\\end{document}`,
    },
    {
        id: 39,
        level: 3,
        title: 'Feuille de style personnalisée',
        description: 'Créer une feuille de style réutilisable',
        initialCode: `% Objectifs :
% - Créer un fichier .sty personnel
% - Définir des commandes et environnements réutilisables
% - Gérer les dépendances des packages
% - Créer un style cohérent

% Fichier mystyle.sty :

\\NeedsTeXFormat{LaTeX2e}
\\ProvidesPackage{monstyle}[2024/01/01 Style personnel pour cours]

% Définir les packages requis et les configurations de base
% Créer des commandes personnalisées pour les besoins fréquents

% Fichier principal.tex :
\\documentclass[12pt,french]{article}
\\usepackage{monstyle}

\\begin{document}

% Tester toutes les fonctionnalités du style

\\end{document}`,
        solution: `% Fichier monstyle.sty :

\\NeedsTeXFormat{LaTeX2e}
\\ProvidesPackage{monstyle}[2024/01/01 Style personnel pour cours]

% Packages requis
\\RequirePackage[margin=2cm]{geometry}
\\RequirePackage[utf8]{inputenc}
\\RequirePackage[T1]{fontenc}
\\RequirePackage{mlmodern}
\\RequirePackage{babel}
\\RequirePackage{amsmath}
\\RequirePackage{amssymb}
\\RequirePackage{tcolorbox}
\\RequirePackage{enumitem}
\\RequirePackage{xcolor}

% Configuration des environnements
\\newtcolorbox{definition}{
    colback=blue!5,
    colframe=blue!35!black,
    title=Définition,
    fonttitle=\\bfseries
}

\\newtcolorbox{theoreme}{
    colback=green!5,
    colframe=green!35!black,
    title=Théorème,
    fonttitle=\\bfseries
}

\\newtcolorbox{remarque}{
    colback=yellow!5,
    colframe=yellow!35!black,
    title=Remarque,
    fonttitle=\\bfseries
}

% Commandes mathématiques
\\newcommand{\\R}{\\mathbb{R}}
\\newcommand{\\N}{\\mathbb{N}}
\\newcommand{\\Z}{\\mathbb{Z}}
\\newcommand{\\Q}{\\mathbb{Q}}
\\newcommand{\\vect}[1]{\\mathbf{#1}}

% Configuration des listes
\\setlist[itemize]{nosep}
\\setlist[enumerate]{nosep}

% Commandes pour le cours
\\newcommand{\\titre}[1]{
    \\begin{center}
        \\Large\\textbf{#1}
    \\end{center}
}

\\newcommand{\\auteur}[1]{
    \\begin{center}
        \\large{#1}
    \\end{center}
}

% Fichier principal.tex :
\\documentclass[12pt,french]{article}
\\usepackage{monstyle}

\\begin{document}

\\titre{Les nombres réels}
\\auteur{J. Dupont}

\\begin{definition}
Un nombre réel est un élément de $\\R$.
\\end{definition}

\\begin{theoreme}
Pour tout $x \\in \\R$, on a $x^2 \\geq 0$.
\\end{theoreme}

\\begin{remarque}
L'ensemble $\\N$ est inclus dans $\\Z$, lui-même inclus dans $\\Q$,
lui-même inclus dans $\\R$.
\\end{remarque}

Soit $\\vect{u}$ un vecteur de $\\R^2$. 

\\smallskip

\\begin{itemize}
\\item Premier point
\\item Deuxième point
\\end{itemize}

\\begin{enumerate}
\\item Premier élément
\\item Deuxième élément
\\end{enumerate}

\\end{document}`,
    },
    {
        id: 40,
        level: 3,
        title: 'Classe personnalisée',
        description: 'Créer une classe pour un type spécifique de document',
        initialCode: `% Objectifs :
% - Créer une classe .cls pour des documents spécifiques
% - Définir des options de classe
% - Gérer l'héritage d'une classe de base
% - Créer des commandes spécifiques à la classe

% Fichier cours.cls :

\\NeedsTeXFormat{LaTeX2e}
\\ProvidesClass{cours}[2024/01/01 Classe pour cours]

% Définir les options de classe
% Hériter d'article
% Charger les packages essentiels
% Créer les commandes pour le cours

% Fichier exemple.tex :
\\documentclass{cours}

\\begin{document}

% Tester les fonctionnalités de la classe

\\end{document}`,
        solution: `% Fichier cours.cls :

\\NeedsTeXFormat{LaTeX2e}
\\ProvidesClass{cours}[2024/01/01 Classe pour cours]

% Options
\\DeclareOption{tp}{\\def\\doctype{TP}}
\\DeclareOption{cours}{\\def\\doctype{Cours}}
\\DeclareOption{td}{\\def\\doctype{TD}}
\\ExecuteOptions{cours}
\\ProcessOptions\\relax

% Classe de base
\\LoadClass[12pt,a4paper]{article}

% Packages requis
\\RequirePackage[margin=2cm]{geometry}
\\RequirePackage[utf8]{inputenc}
\\RequirePackage[T1]{fontenc}
\\RequirePackage{mlmodern}
\\RequirePackage[french]{babel}
\\RequirePackage{amsmath}
\\RequirePackage{amssymb}
\\RequirePackage{fancyhdr}
\\RequirePackage{tcolorbox}
\\RequirePackage{enumitem}

% Configuration en-tête/pied
\\pagestyle{fancy}
\\fancyhf{}
\\renewcommand{\\headrulewidth}{0.4pt}
\\fancyhead[L]{\\@matiere}
\\fancyhead[C]{\\@classe}
\\fancyhead[R]{\\doctype}
\\fancyfoot[C]{\\thepage}

% Nouvelles commandes
\\newcommand{\\matiere}[1]{\\def\\@matiere{#1}}
\\newcommand{\\classe}[1]{\\def\\@classe{#1}}
\\newcommand{\\prof}[1]{\\def\\@prof{#1}}

\\renewcommand{\\maketitle}{
    \\begin{center}
        \\LARGE\\textbf{\\doctype\\ de \\@matiere}\\\\[0.5cm]
        \\Large\\@classe\\\\[0.3cm]
        \\large\\@prof\\\\[0.3cm]
        \\normalsize\\@date
    \\end{center}
}

% Environnements
\\newtcolorbox{definition}{
    colback=blue!5,
    colframe=blue!35!black,
    title=Définition,
    fonttitle=\\bfseries
}

\\newtcolorbox{proposition}{
    colback=green!5,
    colframe=green!35!black,
    title=Proposition,
    fonttitle=\\bfseries
}

% Fichier exemple.tex :
\\documentclass[cours]{cours}

\\matiere{Mathématiques}
\\classe{Terminale S}
\\prof{J. Dupont}
\\date{\\today}

\\begin{document}

\\maketitle

\\section{Introduction}
Ce document illustre l'utilisation de notre classe personnalisée.

\\begin{definition}
Un nombre premier est un entier naturel qui admet exactement
deux diviseurs : 1 et lui-même.
\\end{definition}

\\begin{proposition}
Il existe une infinité de nombres premiers.
\\end{proposition}

\\section{Exercices}
\\begin{enumerate}
\\item Premier exercice
\\item Second exercice
\\end{enumerate}

\\end{document}

% Notes d'utilisation :
% 1. Compiler d'abord la classe cours.cls
% 2. Placer le fichier .cls dans le même dossier que le .tex
% 3. Options disponibles : cours, tp, td
% 4. Les commandes \\matiere, \\classe et \\prof sont obligatoires`,
    },
    {
        id: 41,
        level: 3,
        title: 'Bibliographie',
        description: 'Utiliser biblatex pour gérer la bibliographie',
        initialCode: `% Objectifs :
% - Utiliser biblatex pour la bibliographie
% - Créer un fichier .bib externe
% - Personnaliser le style des citations
% - Gérer différents types de références

% Fichier references.bib :
% Créer différents types d'entrées

% Fichier principal :
\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage[style=alphabetic]{biblatex}

\\begin{document}

% Utiliser différents types de citations
% Créer une bibliographie organisée

\\end{document}`,
        solution: `% Fichier references.bib :
@book{dupont2023,
    author = {Dupont, Jean},
    title = {Mathématiques avancées},
    publisher = {Éditions des Sciences},
    year = {2023},
    address = {Lyon}
}

@article{martin2024,
    author = {Martin, Marie},
    title = {Une nouvelle approche pédagogique},
    journal = {Revue des Mathématiques},
    year = {2024},
    volume = {12},
    number = {3},
    pages = {45--67}
}

@online{mathenligne,
    author = {Lambert, Pierre},
    title = {Cours de mathématiques en ligne},
    year = {2024},
    url = {https://www.mathenligne.fr},
    urldate = {2024-01-15}
}

% Fichier principal :
\\documentclass[12pt,french]{article}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}
\\usepackage[
    style=alphabetic,
    backend=biber,
    sorting=ynt,
    maxbibnames=99,
    giveninits=true
]{biblatex}

\\addbibresource{references.bib}

\\begin{document}

\\section{Introduction}

Les mathématiques modernes \\parencite{dupont2023} ont
beaucoup évolué. Selon \\textcite{martin2024}, les approches
pédagogiques doivent s'adapter. Pour plus d'exercices,
voir \\cite{mathenligne}.

\\section{Types de citations}
\\begin{itemize}
\\item Citation simple: \\cite{dupont2023}
\\item Citation parenthèses: \\parencite{martin2024}
\\item Citation textuelle: \\textcite{mathenligne}
\\item Citation avec page: \\cite[p.~48]{dupont2023}
\\item Citations multiples: \\cite{dupont2023,martin2024}
\\end{itemize}

\\printbibheading
\\printbibliography[heading=subbibliography,
    title={Livres},
    type=book]
\\printbibliography[heading=subbibliography,
    title={Articles},
    type=article]
\\printbibliography[heading=subbibliography,
    title={Ressources en ligne},
    type=online]

% Notes d'utilisation :
% 1. Créer le fichier references.bib
% 2. Compiler avec : pdflatex → biber → pdflatex → pdflatex
% 3. Les styles disponibles : numeric, alphabetic, authoryear...
% 4. Types d'entrées : book, article, online, thesis...

\\end{document}`,
    },
    {
        id: 42,
        level: 3,
        title: 'Documents composites',
        description: 'Créer des documents complexes multi-fichiers',
        initialCode: `% Objectifs :
% - Organiser un document en plusieurs fichiers
% - Gérer les annexes et inclusions
% - Utiliser des styles cohérents
% - Créer des documents professionnels complets

% Structure des fichiers :
% main.tex         - Fichier principal
% config.tex       - Configuration et packages
% titre.tex        - Page de titre
% chap*.tex        - Contenu des chapitres
% annexeA.tex      - Annexes
% biblio.bib       - Bibliographie

% Fichier principal (main.tex) :
\\documentclass[12pt,french]{report}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

% Inclure la configuration et créer le document

\\begin{document}

% Créez un document complet avec :
% 1. Une page de titre professionnelle
% 2. Une table des matières structurée
% 3. Des chapitres importés
% 4. Des annexes
% 5. Une bibliographie

\\end{document}`,
        solution: `% config.tex
\\usepackage{graphicx}
\\usepackage{fancyhdr}
\\usepackage[style=alphabetic]{biblatex}
\\usepackage{appendix}
\\usepackage{tcolorbox}
\\usepackage{hyperref}

\\pagestyle{fancy}
\\fancyhf{}
\\fancyhead[L]{\\leftmark}
\\fancyhead[R]{\\rightmark}
\\fancyfoot[C]{\\thepage}

\\addbibresource{biblio.bib}

% titre.tex
\\begin{titlepage}
\\begin{center}
\\includegraphics[width=0.4\\textwidth]{example-image}\\\\[2cm]
{\\huge\\bfseries Titre du Document}\\\\[1cm]
{\\Large\\itshape Sous-titre}\\\\[2cm]
{\\large Prénom Nom}\\\\[1cm]
{\\large Établissement}\\\\[1cm]
{\\large\\today}
\\vfill
\\end{center}
\\end{titlepage}

% chapitres/chap1.tex
\\chapter{Introduction}
\\section{Contexte}
Texte d'introduction...

\\section{Objectifs}
Liste des objectifs...

% chapitres/chap2.tex
\\chapter{Développement}
\\section{Première partie}
Contenu détaillé...

\\section{Deuxième partie}
Suite du développement...

% annexes/annexeA.tex
\\chapter{Données techniques}
\\section{Spécifications}
Détails techniques...

\\section{Protocoles}
Procédures détaillées...

% biblio.bib
@book{reference1,
    author = {Auteur, A.},
    title = {Titre du livre},
    year = {2024},
    publisher = {Éditeur}
}

% main.tex
\\documentclass[12pt,french]{report}
\\usepackage[margin=2cm]{geometry}
\\usepackage[utf8]{inputenc}
\\usepackage[T1]{fontenc}
\\usepackage{mlmodern}
\\usepackage{babel}

\\input{config}

\\begin{document}

\\input{titre}

\\tableofcontents
\\clearpage

\\input{chap1}
\\input{chap2}

\\begin{appendix}
\\input{annexeA}
\\end{appendix}

\\printbibliography

\\end{document}`,
    },
]

export default latexExercises
