import React from 'react'
import { Viewer, Worker, SpecialZoomLevel, Plugin } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

const customStyles = `
.rpv-core__viewer--dark {
  background-color: #1f2937 !important;
}

.rpv-core__viewer--dark .rpv-core__pages {
  background-color: #1f2937 !important;
}

.rpv-core__viewer--dark .rpv-core__page {
  background-color: #1f2937 !important;
}

.rpv-core__viewer--dark .rpv-core__canvas-layer canvas {
  background-color: #1f2937 !important;
  filter: invert(90%);
  -webkit-filter: invert(90%);
}

.rpv-core__viewer--dark .rpv-core__text-layer {
  filter: invert(90%);
  -webkit-filter: invert(90%);
}

.rpv-core__viewer--dark .rpv-core__text-layer span {
  color: #CCCCCC !important;
}

/* Reste des styles inchangé */
.rpv-core__viewer--dark ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.rpv-core__viewer--dark ::-webkit-scrollbar-track {
  background: #2d3748;
}

.rpv-core__viewer--dark ::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 6px;
  border: 3px solid #2d3748;
}

.rpv-core__viewer--dark ::-webkit-scrollbar-thumb:hover {
  background-color: #718096;
}

.rpv-core__viewer {
  height: 100%;
}

.rpv-core__viewer--dark .rpv-core__inner-pages {
  background-color: #212936;
}

.rpv-core__viewer--dark .rpv-core__page-layer {
  background-color: #212936;
}

.rpv-core__viewer--dark .rpv-default-layout__toolbar {
  background-color: #212936;
  color: white;
}

.rpv-core__viewer--dark .rpv-default-layout__toolbar button {
  color: white;
}

.rpv-core__viewer--dark .rpv-default-layout__toolbar button:hover {
  background-color: #374151;
}
`

const customPlugin = (): Plugin => {
    return {
        install: (pluginFunctions) => {
            const head = document.head || document.getElementsByTagName('head')[0]
            const style = document.createElement('style')
            head.appendChild(style)
            style.type = 'text/css'
            style.appendChild(document.createTextNode(customStyles))
        },
    }
}
const PDFViewer = ({ pdfUrl, isDarkMode }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [], // Supprime la barre latérale
        toolbarPlugin: {
            fullScreenPlugin: {
                onEnterFullScreen: (zoom) => {
                    zoom(SpecialZoomLevel.PageFit)
                },
                onExitFullScreen: (zoom) => {
                    zoom(SpecialZoomLevel.PageWidth)
                },
            },
        },
    })
    const customPluginInstance = customPlugin()
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={pdfUrl}
                    plugins={[defaultLayoutPluginInstance, customPluginInstance]}
                    defaultScale={SpecialZoomLevel.PageWidth}
                    theme={isDarkMode ? 'dark' : 'light'}
                    style={{ border: 'none' }}
                />
            </Worker>
        </div>
    )
}
export default PDFViewer
