import React, { useState, useRef, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'

const ResizablePanels = ({ leftPanel, rightPanel, showLeftPanel, showRightPanel, isDarkMode, height }) => {
    const [leftWidth, setLeftWidth] = useState(50)
    const [isDragging, setIsDragging] = useState(false)
    const containerRef = useRef(null)
    const dragHandleRef = useRef(null)

    const handleMouseDown = useCallback((e) => {
        e.preventDefault()
        setIsDragging(true)
    }, [])

    const handleMouseUp = useCallback(() => {
        setIsDragging(false)
    }, [])

    const handleMouseMove = useCallback(
        (e) => {
            if (!isDragging || !containerRef.current) return
            const containerRect = containerRef.current.getBoundingClientRect()
            const newLeftWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100
            setLeftWidth(Math.max(20, Math.min(80, newLeftWidth)))
        },
        [isDragging]
    )

    const debouncedHandleMouseMove = debounce(handleMouseMove, 10)

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', debouncedHandleMouseMove)
            window.addEventListener('mouseup', handleMouseUp)
        } else {
            window.removeEventListener('mousemove', debouncedHandleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
        }

        return () => {
            window.removeEventListener('mousemove', debouncedHandleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
            debouncedHandleMouseMove.cancel()
        }
    }, [isDragging, debouncedHandleMouseMove, handleMouseUp])

    return (
        <div ref={containerRef} className={`flex overflow-hidden`} style={{ height }}>
            {showLeftPanel && (
                <div style={{ width: showRightPanel ? `${leftWidth}%` : '100%' }} className="h-full">
                    {leftPanel}
                </div>
            )}
            {showLeftPanel && showRightPanel && (
                <div className="flex items-center px-2 h-full">
                    <div
                        ref={dragHandleRef}
                        className={`w-[0px] h-full cursor-col-resize transition-colors duration-200 ${
                            isDarkMode ? 'bg-gray-600 hover:bg-blue-400' : 'bg-gray-300 hover:bg-blue-500'
                        }`}
                        onMouseDown={handleMouseDown}
                    />
                </div>
            )}
            {showRightPanel && (
                <div style={{ width: showLeftPanel ? `${100 - leftWidth}%` : '100%' }} className="h-full">
                    {rightPanel}
                </div>
            )}
        </div>
    )
}

export default ResizablePanels
